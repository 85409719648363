import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService, ToastService } from '@dmspro/notification';
import { ControlValidation, CustomValidators, DecodeToken } from '@dmspro/shared-lib';
import { DriverBalanceDetailsModel } from 'src/models/driver-balance-details.model';
import { DriverPaymentRequestModel } from 'src/models/driver-payment.request.model';
import { DriverResponseModel } from 'src/models/drivers-response.model';
import { UserManagementService } from 'src/services/user-management.service';

@Component({
  selector: 'app-driver-payment',
  templateUrl: './driver-payment.component.html',
  styleUrls: ['./driver-payment.component.scss']
})
export class DriverPaymentComponent implements AfterViewInit {

  isFormLoading: boolean = false;

  @ViewChild('driverPaymentModal')
  driverPaymentModal: ElementRef<any>;

  drivers: DriverResponseModel[] = [];
  selecteddriverBalanceDetails: DriverBalanceDetailsModel;
  driverPaymentForm: FormGroup;

  constructor(private render: Renderer2, private userService: UserManagementService,
    private toastService: ToastService, private loaderService: LoaderService) {

    this.driverPaymentForm = new FormGroup({
      driverId: new FormControl(null, [Validators.required]),
      paymentMethod: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required, Validators.maxLength(6), CustomValidators.DecimalValidator()]),
      notes: new FormControl(null, [Validators.maxLength(250)])
    });

  }

  ngAfterViewInit(): void {
    const tokenDetails = DecodeToken.getToken();
    if (tokenDetails.has_subscribed) {
       this.loaderService.startLoader();
      this.userService.getDrivers().subscribe({
        next: res => {
          if (res.success) {
            this.drivers = [...res.data];
          }
          this.loaderService.stopLoader();
        },
        error: err => {
          this.loaderService.stopLoader();
          this.toastService.notification.error(err.message);
        }
      });
    }

  }

  openModal() {
    this.driverPaymentForm.reset();
    this.render.addClass(this.driverPaymentModal.nativeElement, 'is-active');
  }

  closeModal() {
    this.driverPaymentForm.reset();
    this.render.removeClass(this.driverPaymentModal.nativeElement, 'is-active');
  }

  isDriverPaymentBasicVisible(): boolean {
    return !!this.driverPaymentForm.controls['driverId'].value;
  }

  controlErrorMessage(controlName: string): string | undefined {
    return ControlValidation.ControlError(this.driverPaymentForm.controls[controlName] as any);
  }

  driverSelectionChange(selection: any) {
    const driverId = selection.value;
    this.loaderService.startLoader();
    this.userService.getDriversBalanceDetails(driverId).subscribe({
      next: res => {
        if (res.success) {
          this.selecteddriverBalanceDetails = { ...res.data };
          this.driverPaymentForm.controls['amount'].setValue(this.selecteddriverBalanceDetails.income - this.selecteddriverBalanceDetails.received)
        }
        this.loaderService.stopLoader();
      },
      error: err => {
        this.loaderService.stopLoader();
        this.toastService.notification.error(err.message);
      }
    });
  }


  payNow() {
    const control = this.driverPaymentForm.controls;
    const driverPaymentRequest: DriverPaymentRequestModel = {
      driverId: this.driverPaymentForm.controls['driverId'].value,
      amount: this.driverPaymentForm.controls['amount'].value,
      date: new Date(),
      paymentType: this.driverPaymentForm.controls['paymentMethod'].value,
      notes: this.driverPaymentForm.controls['notes'].value,
    };
    this.isFormLoading = true;
    this.userService.driverPayment(driverPaymentRequest).subscribe({
      next: res => {
        if (res.success) {
          this.closeModal();
          this.toastService.notification.success('Payment credited successfully');
        }
        this.isFormLoading = false;
      },
      error: err => {
        this.isFormLoading = false;
        this.closeModal();
        this.toastService.notification.error(err.message);
      }
    });
  }
}
