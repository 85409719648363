import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, Observable } from "rxjs";
import { AuthenticationService } from "src/services/authentication.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if(err instanceof HttpErrorResponse){
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    location.reload();
                }
                if (err.status === 403) {
                    return throwError(() => new Error('Not allowed'));
                }
                if (err.status === 400) {
                    const error = err.error?.errors?.join(',') || err.statusText;
                    return throwError(() => new Error(error));
                }
            }
            return throwError(() => new Error(err.message));
        }));
    }
}