import { DatePipe } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';

import { AdminPrintLoadService, ExtraAmountModel, LoadBillingModel, LoadInvoiceItems, LoadShipperModel, LoadTransportDetailsModel } from '@dmspro/print-documents';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-shipment-invoice',
  templateUrl: './print-shipment-invoice.component.html',
  styleUrls: ['./print-shipment-invoice.component.scss']
})
export class PrintShipmentInvoiceComponent {

  constructor(private adminPrintLoadService: AdminPrintLoadService, private render: Renderer2, private datePipe: DatePipe) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  async printDocument() {

    const loadBilling = <LoadBillingModel>{
      brokerAddress: 'Hno 15, RD Colony, St No 8, Old Suraj Nagari, Abohar, Punjab 151216, INDIA',
      brokerEmailAddress: 'raheja.abhi@hotmail.com',
      brokerMobile: '9914964911',
      brokerName: 'Abhishek Raheja',
      brokerTermPlan: '50 Days',
      loadNumber: '123456789'
    };
    const invoiceItems = <LoadInvoiceItems[]>[
      {
        itemName: 'Cartoon',
        fsc: 500,
        pds: 100,
        qty: 10,
        rate: 400
      }
    ];

    const otherCharges = <ExtraAmountModel[]>[
      {
        description: 'Test Description other charges',
        amount: 100
      },
      {
        description: 'Test Description other charges',
        amount: 100
      }
    ];
    const advanceCharges = <ExtraAmountModel[]>[
      {
        description: 'Test Description advance charges',
        amount: 50
      }
    ];


    const loadShipper: LoadShipperModel[] = [
      {
        date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
        shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
        time: this.datePipe.transform(new Date(), 'HH:mm a'),
        description: 'asdaaaa',
        loadTbl: [{
          qty: "1",
          poNo: "",
          type: 'BOX',
          value: "200",
          weight: "2T"
        }],
        isLocationVerified: true,
        location: 'WR8P+J7J, Tagore Nagar, Civil Lines, Ludhiana, Punjab 141001',
        shipperName: 'Test Shipper 1',
        consignees: [
          {

            date: '',
            shippedDate: '',
            time: '',
            description: '',
            loadTbl: [],
            isLocationVerified: true,
            location: 'WR8P+J7J, Tagore Nagar, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Shipper 1'
          },
          {

            date: '',
            shippedDate: '',
            time: '',
            description: '',
            loadTbl: [],
            isLocationVerified: false,
            location: 'WR8P+J7J, Tagore Nagar11, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Shipper 2'
          }
        ]
      },
      {

        date: '',
        shippedDate: '',
        time: '',
        description: '',
        loadTbl: [],
        isLocationVerified: false,
        location: 'WR8P+J7J, Tagore Nagar11, Civil Lines, Ludhiana, Punjab 141001',
        shipperName: 'Test Shipper 2',
        consignees: [
          {

            date: '',
            shippedDate: '',
            time: '',
            description: '',
            loadTbl: [],
            isLocationVerified: true,
            location: 'WR8P+J7J, Tagore Nagar, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Shipper 1'
          },
          {
            date: '',
            shippedDate: '',
            time: '',
            description: '',
            loadTbl: [],
            isLocationVerified: false,
            location: 'WR8P+J7J, Tagore Nagar11, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Shipper 2'
          }
        ]
      }
    ];
    const loadTransport = <LoadTransportDetailsModel>{
      driverEmailAddress: 'raheja.abhi@hotmail.com',
      driverMobileNumber: '9914964911',
      driverName: 'Abhishek',
      trailerName: 'ABC TRAILER',
      trailerNumber: '119966',
      trailerTypeName: 'FREEZER',
      truckName: 'ABC TRUCK',
      truckNumber: '123123'
    };



    const testDocument = await this.adminPrintLoadService.getLoadInvoice(loadBilling, invoiceItems, otherCharges, advanceCharges, loadShipper, loadTransport);
    try {
      const pdfDocGenerator = pdfMake.createPdf(testDocument);
      // pdfDocGenerator.getDataUrl((dataUrl) => {
      //   const targetElement = document.querySelector('#iframeContainer');
      //   const iframe = document.createElement('iframe');
      //   iframe.src = dataUrl;
      //   this.render.setStyle(iframe, 'height', '100%');
      //   this.render.setStyle(iframe, 'width', '100%');
      //   targetElement.appendChild(iframe);
      // });
      await pdfMake.createPdf(testDocument).open();
    }
    catch (err: any) {
      console.log(err);
    }

  }
}
