<div>
    <mat-toolbar class="is-flex is-flex-direction-row has-background-white box is-radiusless p-0">
        <button mat-icon-button class="ml-2" *ngIf="role !== roleConstants.new" type="button" (click)="sideNavClick()"
            aria-label="Example icon-button with menu icon">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="is-flex-grow-1" *ngIf="role === roleConstants.new">
            <p class="m-0 ml-2">
                <img src="../../assets/img_app/logo_blue.svg" height="28">
                <span class="ml-2 has-text-weight-semibold" style="font-size: 16px;">Dispatch Management</span>
            </p>
        </div>
        <div class="is-align-self-center is-flex-grow-1" *ngIf="role != roleConstants.new">
            <input type="text" class="input is-small mobile-screen is-rounded" placeholder="search"
                style="width: 250px;">
        </div>

        <button mat-icon-button style="color:#0D6EFD" *ngIf="role != roleConstants.new && isSubscribed"
            [matMenuTriggerFor]="menuActionHelper">
            <mat-icon>add_circle</mat-icon>
        </button>
        <mat-menu #menuActionHelper="matMenu">
            <button *dmsAppFeatures="appFeatures.USER_MANAGEMENT" mat-menu-item (click)="openNewUserModal()">
                <span>Add Users</span>
            </button>
            <button mat-menu-item (click)="openDriverPaymentModal()">
                <span>Drivers Payment</span>
            </button>
            <!-- <button mat-menu-item>
                <span>Add Expenses</span>
            </button> -->
        </mat-menu>

        <button mat-icon-button [matBadge]="batchCount===0?'':batchCount"
            *ngIf="(role === roleConstants.admin || role=== roleConstants.emp)" class="notification-badge"
            [matMenuTriggerFor]="menuNotification">
            <mat-icon>notifications</mat-icon>
        </button>
        <mat-menu #menuNotification="matMenu">
            <div class="is-flex is-flex-direction-column p-3" style="width: 270px; max-height: 400px;">
                <div *ngIf="notifications.length>0 else no_notification">
                    <div>
                        <div class="is-flex is-flex-direction-row">
                            <div class="is-flex-grow-1">
                                Notifications
                            </div>
                            <div>
                                <a class="is-size-7">Mark all as read</a>
                            </div>
                        </div>
                    </div>

                    <div class="is-flex-grow-1 mt-4 p-0 pr-2 master-menu is-overflow" cdkMenu>
                        <div class="menu-item" *ngFor="let item of notifications">
                            <div class="is-flex is-flex-direction-row  px-1">
                                <div class="is-align-self-center">
                                    <img [src]="item.image" class="is-block" width="30" height="30">
                                </div>
                                <div class="is-flex-grow-1 ml-2 is-align-self-center">
                                    <span class="p-0 is-block is-size-7 trim-text"
                                        style="height: 23px; width: 180px;">{{item.message}}</span>

                                    <span class="p-0 is-block is-size-7  trim-text has-text-grey-light"
                                        style="height: 23px; width: 175px; margin-top: -2px;">{{item.date.toLocaleString()}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ng-template #no_notification>
                <div style="height: 300px;" class="is-flex is-align-self-center is-align-items-center">
                    <span style="color: lightgray;"> NO NOTIFICAION AVAILABLE</span>
                </div>
            </ng-template>
        </mat-menu>
        <div class="mt-1">
            <div class="is-flex is-flex-direction-column">
                <span class="is-size-6">{{userInformation.name}}</span>
                <span class="is-size-7 is-align-self-flex-end is-uppercase" style="margin-top: -15px;">({{role}})</span>
            </div>

        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <figure class="image is-32x32" *ngIf="!!userInformation.userImage else userimagetemplate">
                <img class="is-rounded" alt="user" src="{{userInformation.userImage}}">
            </figure>
        </button>
        <mat-menu #menu="matMenu">
            <button [routerLink]="['settings']" routerLinkActive="router-link-active" *ngIf="role != roleConstants.new"
                mat-menu-item>
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
            </button>
            <!-- <button mat-menu-item *ngIf="role != roleConstants.new">
                <mat-icon>account_balance_wallet</mat-icon>
                <span>Balance</span>
            </button> -->
            <mat-divider *ngIf="role != roleConstants.new"></mat-divider>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </mat-toolbar>
</div>

<ng-template #userimagetemplate>
    <mat-icon>account_circle</mat-icon>
</ng-template>

<app-new-user #newuser></app-new-user>
<app-driver-payment *ngIf="(role === roleConstants.admin || role=== roleConstants.emp) && isSubscribed"
    #driverPayment></app-driver-payment>
