import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginGuard } from "src/guards/login.guard";
import { AuthGuard } from "src/guards/app-auth.guard";
import { CompanyAuthGuard } from "src/guards/company.guard";
import { SubscriptionGuard } from "src/guards/subscription.guard";
import { SuperAdminGuard } from "src/guards/super-admin.guard";
import { LoginComponent } from "../components/login/login.component";
import { AccountVerificationComponent } from "../components/login/right-control/account-verification/account-verification.component";
import { SignInComponent } from "../components/login/right-control/sign-in/sign-in.component";
import { SignUpComponent } from "../components/login/right-control/sign-up/sign-up.component";
import { MainpageComponent } from "../components/modules/landingpage/mainpage/mainpage.component";
import { ForgetComponent } from "../components/login/right-control/forget/forget.component";
import { RecoveryComponent } from "../components/login/right-control/recovery/recovery.component";
import { DispatchManagementGuard } from "src/guards/dispatch-management.guard";
import { VerifyUserAccountComponent } from "../components/login/right-control/verify-user-account/verify-user-account.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("@dmspro/website").then((x) => x.DmsWebsiteModule),
  },
  // Login
  {
    path: "login",
    component: LoginComponent,
    children: [{
      path: '',
      component: SignInComponent
    },
    {
      path: 'signup',
      component: SignUpComponent
    },
    {
      path: 'forget',
      component: ForgetComponent
    },
    {
      path: 'account/verification',
      component: AccountVerificationComponent
    },
    {
      path: 'resetpassword',
      component: RecoveryComponent
    },
    {
      path: 'resetpassword',
      component: RecoveryComponent
    },
    {
      path:'verifyuseraccount',
      component: VerifyUserAccountComponent
    }
  ]
  },
  // // Payment
  {
    path: "payment",
    canActivate: [AuthGuard],
    canActivateChild: [LoginGuard],
    loadChildren: () =>
      import("@dmspro/payment").then((x) => x.DmsPaymentModule)
  },
  // // User Login
  {
    path: "account",
    component: MainpageComponent,
    canActivate: [AuthGuard],
    canActivateChild: [LoginGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("@dmspro/dashboard").then((x) => x.DMSDashboardModule)
      },
      {
        path: "customer",
        loadChildren: () =>
          import("@dmspro/customer").then((x) => x.DmsCustomerModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard, DispatchManagementGuard]
      },
      {
        path: "employee",
        loadChildren: () =>
          import("@dmspro/employee").then((x) => x.DmsEmployeeModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard]
      },
      {
        path: "foreigner",
        loadChildren: () =>
          import("@dmspro/foreigner").then((x) => x.DmsForeignerModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard, DispatchManagementGuard]
      },
      {
        path: "shipper",
        loadChildren: () => import('@dmspro/shipper').then((x) => x.DmsShipperModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard, DispatchManagementGuard]
      },
      {
        path: "driver",
        loadChildren: () =>
          import("@dmspro/driver").then((x) => x.DmsDriverModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard]
      },
      {
        path: "truck",
        loadChildren: () => import('@dmspro/truck').then((x) => x.DmsTruckModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard]
      },
      {
        path: "trailer",
        loadChildren: () => import('@dmspro/trailer').then((x) => x.DmsTrailerModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard]
      },
      {
        path: "dispatch",
        loadChildren: () => import('@dmspro/load').then((x) => x.DmsLoadModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard, DispatchManagementGuard]
      },
      {
        path: "expenses",
        loadChildren: () => import('@dmspro/expenses').then((x) => x.DmsExpensesModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard, DispatchManagementGuard]
      },
      {
        path: "settings",
        loadChildren: () => import('@dmspro/settings').then((x) => x.DmsSettingsModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard]
      },
      {
        path: "reports",
        loadChildren: () => import('@dmspro/reports').then((x) => x.DmsReportsModule),
        canActivate: [CompanyAuthGuard, SubscriptionGuard]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ],
  },
  // Super Admin Login
  {
    path: "super",
    component: MainpageComponent,
    canActivate: [AuthGuard],
    canActivateChild: [SuperAdminGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("@dmspro/super-dashboard").then((x) => x.DmsSuperDashboardModule),
      },
      {
        path: "customer",
        loadChildren: () =>
          import("@dmspro/super-customer").then((x) => x.DmsSuperCustomerModule)
      },
      {
        path: "package",
        loadChildren: () =>
          import("@dmspro/super-subscription").then((x) => x.DmsSuperSubscriptionModule)
      },
      {
        path: "reports",
        loadChildren: () =>
          import("@dmspro/super-reports").then((x) => x.DmsSuperReportsModule)
      },
      {
        path: 'quote',
        loadChildren: () => import('@dmspro/super-quote').then((x) => x.DmsSuperQuoteModule)
      },
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      }
    ],
  },
  {
    path: '**',
    loadChildren: () => import("@dmspro/website").then((x) => x.DmsWebsiteModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
