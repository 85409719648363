import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "src/services/authentication.service";

@Injectable({ providedIn: 'root' })
export class CompanyAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.currentUserValue?.companyResponse.id) {
           return true;
        }
        this.router.navigate(['/account/dashboard']);
        return false;
    }
}