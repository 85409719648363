import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";


export class Configuration {
    baseUrl!: string;
    identityEndpoints!: IdentityEndpointConfiguration;
    userManagemntEndpointConfiguration!: UserManagemntEndpointConfiguration;
}



export class IdentityEndpointConfiguration {
    login!: string;
    register!: string;
    verification!: string;
    verifyUserAccount!: string;
    forgetPassword!: string;
    verifyResetLink!: string;
    resetPassword!: string;
}

export class UserManagemntEndpointConfiguration {
    registeruser!: string;
    resendactivation!: string;
}

export const identityConfiguration: Configuration = {
    baseUrl: endpoints.identity,
    identityEndpoints: {
        login: apiRoutes.authorization.login,
        register: apiRoutes.authorization.register,
        verification: apiRoutes.authorization.verification,
        verifyUserAccount: apiRoutes.user_management.verification.verifyUserAccount,
        forgetPassword: apiRoutes.authorization.forgetPassword,
        verifyResetLink: apiRoutes.authorization.verifyResetLink,
        resetPassword: apiRoutes.authorization.resetpassword
    },
    userManagemntEndpointConfiguration: {
        registeruser: apiRoutes.userManagemntEndpointConfiguration.registeruser,
        resendactivation: apiRoutes.userManagemntEndpointConfiguration.resendactivation
    }
}