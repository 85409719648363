import { apiRoutes } from "../../../../environments/env.routes";
import { endpoints } from "../../../../environments/environment";

export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    notificationEndpoints!: NotificationEndpoints;
}


export class NotificationEndpoints {
    getNotifications!: string;
}

export const notificationConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        notificationEndpoints: {
            getNotifications: apiRoutes.user_management.notifications.getnotification,
        }
    }
}