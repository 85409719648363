import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlValidation } from '@dmspro/shared-lib';
import { ToastService } from '@dmspro/notification';
import { AuthenticationService } from 'src/services/authentication.service';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  isFormLoading = false;
  public showPassword: boolean = false;
  loginForm: FormGroup;
  constructor(private authService: AuthenticationService, private loginService: LoginService, private toastService: ToastService) {
    this.loginForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    //this.authService.logout();
  }

  controlErrorMessage(controlName: string): string {
    return ControlValidation.ControlError(this.loginForm.controls[controlName] as any);
  }

  loginAccount() {
    this.isFormLoading = true;
    return this.loginService.login(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value).subscribe({
      next: (res) => {
        if (res.success) {
          this.authService.login(res.data);
          this.isFormLoading = false;
          this.toastService.notification.success(`Hello ${res.data.name}, \nLoggedin Successfully`)
        }
      },
      error: (err) => {
        this.isFormLoading = false;
        this.toastService.notification.error(err.message);
      },
      complete: () => {
        this.isFormLoading = false;
      }
    });
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
