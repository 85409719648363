import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration{
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}
export class UserEndpointConfiguration {
    expensesEndpoints!: ExpensesEndpoints;
}
export class ExpensesEndpoints {
    create!: string;
    getAllExpenses!: string;
    uploadPictures!:string;

}
export const expensesConfiguration:Configuration={
    baseUrl:endpoints.user_management,
    userEndpoints:{
        expensesEndpoints:{
            create:apiRoutes.user_management.expenses.create,
            getAllExpenses:apiRoutes.user_management.expenses.getAllExpenses,
            uploadPictures:apiRoutes.user_management.expenses.uploadPictures
        }
    }

}