import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TokenValueResponse } from "src/models/token.response";
import { User } from "src/models/user.model";
import { SettingsCommunicationService } from "@dmspro/settings";
import { DashboardNotificationService } from "@dmspro/dashboard";
import { PaymentRoutingService } from "@dmspro/payment";
import { LoginService } from "./login.service";
import { DecodeToken, LocalConstant } from "@dmspro/shared-lib";


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public tokenDetailsSubject: BehaviorSubject<TokenValueResponse>;
    constructor(private settingService: SettingsCommunicationService,
        private unsubscribedDashboard: DashboardNotificationService,
        private loginService: LoginService,
        private paymentService: PaymentRoutingService) {
        this.tokenDetailsSubject = new BehaviorSubject<TokenValueResponse>({} as any);
        const userDetails = localStorage.getItem(LocalConstant.currentUser);
        const jsonResult = JSON.parse(userDetails ?? '{}');
        this.validateToken(jsonResult?.tokenResponse?.token);
        this.currentUserSubject = new BehaviorSubject<User>(jsonResult);
        this.settingServiceCommunication();
        this.unsubscribedDashboardCommunication();
        this.paymentServiceCommunication();
    }

    private settingServiceCommunication() {
        this.settingService.$updateProfileDetail.subscribe(x => {
            this.currentUserSubject.next(x);
        });
    }

    private unsubscribedDashboardCommunication() {
        this.unsubscribedDashboard.authenticationService$.subscribe(x => {
            this.login(x);
        });
    }


    private paymentServiceCommunication() {
        this.paymentService.authenticationService$.subscribe(x => {
            this.login(x);
        });
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentTokenValue(): TokenValueResponse {
        return this.tokenDetailsSubject.value;
    }

    public login(user: User): any {
        localStorage.setItem(LocalConstant.currentUser, JSON.stringify(user));
        this.validateToken(user.tokenResponse?.token);
        this.currentUserSubject.next(user);
    }

    public loadPrinterSettings() {
        this.loginService.printerSettings().subscribe({
            next:res=>{
                if(res.success){
                    localStorage.setItem(LocalConstant.printerSetting,JSON.stringify(res.data));
                }
            },
            error:err=>{
                //not required
            }
        })
    }

    public logout(): void {
        localStorage.removeItem(LocalConstant.currentUser);
        localStorage.removeItem(LocalConstant.printerSetting);
        this.currentUserSubject.next(new User());
    }

    private validateToken(token: string) {
        if (token) { 
            const tokenResult = DecodeToken.getToken();
            if (tokenResult.expiry < new Date()) {
                this.logout();
                return;
            }
            this.tokenDetailsSubject.next(tokenResult);
        }

    }
}