import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-control',
  templateUrl: './right-control.component.html',
  styleUrls: ['./right-control.component.scss']
})
export class RightControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
