import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSelectModule } from "@angular/material/select";
import {CdkListboxModule} from '@angular/cdk/listbox';
import {CdkMenuModule} from '@angular/cdk/menu';

@NgModule({
    exports: [
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatMenuModule,
        MatDividerModule,
        CdkListboxModule,
        MatListModule,
        MatExpansionModule,
        MatBadgeModule,
        CdkMenuModule,
        MatSelectModule
    ]
})

export class AppMaterialModule { }