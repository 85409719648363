import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    truckEndpoints!: TruckEndpoints;
}

export class TruckEndpoints {
    create!: string;
    update!: string;
    getAllTruck!: string;
    getTruckById!: string;
    deleteTruck!: string;
    getDrivers!: string;
    uploadImage!: string;
    uploadDocuments!: string;
    fetchDocuments!: string;
    updateDocument!: string;
    deleteDocument!: string;
    downloadCSV!: string;
    changeStatus!: string;
}

export const truckConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        truckEndpoints: {
            create: apiRoutes.user_management.truck.create,
            update: apiRoutes.user_management.truck.update,
            getAllTruck: apiRoutes.user_management.truck.getAll,
            getTruckById: apiRoutes.user_management.truck.getDetails,
            deleteTruck: apiRoutes.user_management.truck.delete,
            getDrivers: apiRoutes.user_management.truck.getDrivers,
            changeStatus: apiRoutes.user_management.truck.changeStatus,
            deleteDocument: apiRoutes.user_management.truck.deleteDocument,
            downloadCSV: apiRoutes.user_management.truck.downloadCSV,
            fetchDocuments: apiRoutes.user_management.truck.fetchDocuments,
            updateDocument: apiRoutes.user_management.truck.updateDocument,
            uploadDocuments: apiRoutes.user_management.truck.uploadDocuments,
            uploadImage: apiRoutes.user_management.truck.uploadImage
        },
    }
}