import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@dmspro/notification';
import { ControlValidation, CustomValidators } from '@dmspro/shared-lib';
import { UserManagementService } from 'src/services/user-management.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  @ViewChild('newUserFormModal')
  newUserFormModal: ElementRef<any>;

  newUserForm: FormGroup;
  isFormLoading = false;
  constructor(private render: Renderer2, private toastService: ToastService, private userManagementService: UserManagementService) {
    this.newUserForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      email: new FormControl(null, [Validators.required, Validators.email])
    });

  }

  ngOnInit(): void {

  }

  openModal() {
    this.newUserForm.reset();
    this.render.addClass(this.newUserFormModal.nativeElement, 'is-active');
  }

  closeModal() {
    this.newUserForm.reset();
    this.render.removeClass(this.newUserFormModal.nativeElement, 'is-active');
  }

  controlErrorMessage(controlName: string): string | undefined {
    return ControlValidation.ControlError(this.newUserForm.controls[controlName] as any);
  }


  onSubmit() {
    const controls = this.newUserForm.controls;
    this.isFormLoading = true;
    this.userManagementService.createUserAccount(controls['name'].value, controls['email'].value)
      .subscribe({
        next: res => {
          if (res.success) {
            this.isFormLoading = false;
            this.closeModal();
            this.toastService.notification.success('Account created successfully');
          }
        },
        error: err => {
          alert(err.message);
          this.isFormLoading = false;
        }
      })
  }

}
