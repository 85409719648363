import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-nav-menu-item',
  templateUrl: './side-nav-menu-item.component.html',
  styleUrls: ['./side-nav-menu-item.component.scss']
})
export class SideNavMenuItemComponent {

  @Input('router-link')
  routerLink: string | undefined;

  @Input('menu-icon')
  menuIcon: string | undefined;

  @Input('menu-text')
  menuText: string | undefined;
  
  constructor() { }

}
