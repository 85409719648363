<div class="has-text-weight-bold is-size-4" style="min-width: 350px;">
    Reset Password
</div>
<div class="has-text-grey mt-1">
    Please enter new details to reset your account.
</div>
<form [formGroup]="resetForm" class="mt-5" (submit)="resetPassword()">
    <div>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="newPassword"
                placeholder="New Password">
            <mat-icon matSuffix class="pointer"
                (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
            <mat-error>
                {{controlErrorMessage('newPassword')}}
            </mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="showConfPassword ? 'text' : 'password'" formControlName="confPassword"
            placeholder="Confirm Password">
        <mat-icon matSuffix class="pointer"
            (click)="toggleConfPasswordVisibility()">{{showConfPassword?'visibility_off':'visibility'}}</mat-icon>
            <mat-error>
                {{controlErrorMessage('confPassword')}}
            </mat-error>
        </mat-form-field>
    </div>


    <button mat-raised-button [disabled]="(resetForm.invalid || isFormLoading)" type="submit" color="primary"
        class="w-100">
        Change Password
        <mat-icon *ngIf="isFormLoading">
            <mat-spinner color="accent" diameter="18">
            </mat-spinner>
        </mat-icon>
    </button>

</form>