import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { InvoiceTableBodyItemsModel } from './models/invoice-table-body-item.model';
import { InvoiceDetails, InvoiceToModel } from './models/invoice-to.model';
import { InvoiceTotalModel } from './models/invoice-total.model';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent {


  constructor() {

  }
}
