import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/models/user.model';
import { AuthenticationService } from 'src/services/authentication.service';

@Component({
  selector: 'app-super-nav-header',
  templateUrl: './super-nav-header.component.html',
  styleUrls: ['./super-nav-header.component.scss']
})
export class SuperNavHeaderComponent implements OnInit {

  public isOpen: boolean;
  public sideNav$: BehaviorSubject<boolean>;
  public userInformation: User;
  constructor(private authService: AuthenticationService, private router: Router) {
    this.isOpen = true;
    this.sideNav$ = new BehaviorSubject(this.isOpen);
    this.userInformation = authService.currentUserValue as any;
  }

  sideNavClick() {
    this.sideNav$.next(this.isOpen);
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/home']);
  }

}
