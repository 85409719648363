import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { ToastService } from '@dmspro/notification';
import { Router } from '@angular/router';
import { DashboardNotificationService } from '@dmspro/dashboard';
import { HomeService } from '@dmspro/website';
import { PaymentRoutingService } from '@dmspro/payment';
import { SuperDashboardNotificationService } from '@dmspro/super-dashboard';
import { AuthenticationService } from 'src/services/authentication.service';
import { RequestDemoService } from 'src/services/request-demo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isAuthenticated: boolean = false;

  requestFormSubscription: Subscription;

  constructor(
    private homeService: HomeService,
    private toastService: ToastService,
    private authService: AuthenticationService,
    private paymentRoutingService: PaymentRoutingService,
    private dashboardService: DashboardNotificationService,
    private superDashboard: SuperDashboardNotificationService,
    private requestDemoService: RequestDemoService,
    private router: Router) {

  }

  ngOnDestroy(): void {
    this.requestFormSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.logoutServices();
    this.navigationService();
    this.requestDemoFormSubmit();

  }

  private navigationService(): void {

    this.homeService.loginEvent.subscribe(x => {
      if (x)
        this.router.navigate([x])
    });

    this.dashboardService.dashboardRouting$.subscribe(res => {
      if (res)
        this.router.navigate([res]);
    });

    this.paymentRoutingService.paymentRouting$.subscribe(res => {
      if (res) {
        this.router.navigate([res]);
      }
    });

  }

  private logoutServices(): void {
    this.paymentRoutingService.paymentLogout$.subscribe(res => {
      this.logoutUser();
    });

    this.dashboardService.dashboardLogout$.subscribe(res => {
      this.logoutUser();
    });

    this.superDashboard.dashboardLogout$.subscribe(res => {
      this.logoutUser();
    });
  }

  private logoutUser() {
    this.authService.logout();
    this.router.navigate(['/login']);
    this.toastService.notification.info('Please login again');
  }

  private requestDemoFormSubmit() {
    this.requestFormSubscription = this.homeService.requestForDemo$.subscribe(formData => {
      this.requestDemoService.createUserAccount({
        email: formData.emailAddress,
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobileNo: formData.mobile,
        message: formData.message
      }).pipe(take(1)).subscribe({
        next: (res) => {
          if (res.success)
            this.toastService.notification.success(res.data);
          else {
            this.toastService.notification.error(res.errors.join(','));
          }
        },
        error: (err) => {
          this.toastService.notification.error(err.message);
        }
      })
    });
  }





  testNotification() {
    this.toastService.alert.deleteMessage("Question 99", "body message").pipe(take(1)).subscribe(res => {
    });
  }
}

