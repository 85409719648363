import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';

@Component({
  selector: 'app-super-side-nav',
  templateUrl: './super-side-nav.component.html',
  styleUrls: ['./super-side-nav.component.scss']
})
export class SuperSideNavComponent implements OnInit {

  @ViewChild('sideNav') sideNav: ElementRef = {} as any;
  isToggled: boolean = true;
  constructor(private render: Renderer2, private authService: AuthenticationService) { }

  ngOnInit(): void {
    
  }

  toggleButton() {
    const classContains = this.sideNav.nativeElement.classList.contains('side-nav-open');
    if (classContains) {
      this.render.removeClass(this.sideNav.nativeElement, 'side-nav-open');
      this.render.addClass(this.sideNav.nativeElement, 'side-nav-close');
    }
    else {
      this.render.addClass(this.sideNav.nativeElement, 'side-nav-open');
      this.render.removeClass(this.sideNav.nativeElement, 'side-nav-close');
    }
  }

  menuListVisible(element: any) {
    const classContains = element.classList.contains('close-list');
    if (classContains) {
      this.render.removeClass(element, 'close-list');
      this.render.addClass(element, 'open-list');
    }
    else{
      this.render.removeClass(element, 'open-list');
      this.render.addClass(element, 'close-list');
    }
  }
}

