import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    sharedEndpoints!: SharedEndpointConfiguration;
}

export class SharedEndpointConfiguration {
    regionEndpoints!: RegionEndpoints;
}

export class RegionEndpoints {
    country!: string;
    state!: string;
    city!: string;
}

export const sharedConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    sharedEndpoints: {
        regionEndpoints: {
            country: apiRoutes.user_management.region.country,
            state: apiRoutes.user_management.region.state,
            city: apiRoutes.user_management.region.city
        }
    }
}