import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppFeaturesConstant, AppRoleConstant, DecodeToken } from '@dmspro/shared-lib';
import { NavHeaderComponent } from 'src/app/components/shared/nav-header/nav-header.component';
import { SideNavComponent } from 'src/app/components/shared/side-nav/side-nav.component';
import { AuthenticationService } from 'src/services/authentication.service';
import { IPService } from 'src/services/ip.service';
import { endpoints } from "src/environments/environment";

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit, AfterViewInit {

  @ViewChild('sideNav') sideNav: SideNavComponent;
  @ViewChild('headerNav') headerNav: NavHeaderComponent;

  role: string;
  roleConstants = {
    admin: AppRoleConstant.admin,
    superAdmin: AppRoleConstant.super_admin,
    new: AppRoleConstant.new,
    emp: AppRoleConstant.employee
  };

  constructor(private authService: AuthenticationService, private ipService: IPService) {

  }

  async ngOnInit(): Promise<void> {
    this.role = this.authService.currentUserValue.role;
    this.authService.currentUserSubject.subscribe({
      next: res => {
        if (!!res?.id) {
          const token = DecodeToken.getToken();
          if (token.features.findIndex(x => x === AppFeaturesConstant.SUPPORT_SYSTEM) > -1)
            this.addMsg91Support();
        } else {
          //remove
          this.removeMsg91Support();
        }
      }
    });

  }

  addMsg91Support(): void {
    const tokenDetail = DecodeToken.getToken();
    const user = this.authService.currentUserValue;
    console.log('Support system enabled');
    let ipAddress: string = '';
    // try {    //   const ipResponse = await firstValueFrom(this.ipService.getIpAddress());
    //   if (ipResponse?.ip) {
    //     ipAddress = ipResponse.ip
    //   }
    // }
    // catch (err) {
    //   //not required
    // }
    const env = endpoints.environment === 'pro' ? '' : ` - ${endpoints.environment}`;
    const msgpayload = `initChatWidget({
      widgetToken: 'd719a',
      hide_launcher: false,
      show_close_button: true,
      launch_widget: false,
      show_send_button: true,
      unique_id: '${user.username}${env}',
      name: '${user.name}${env}',
      number: '${!user.mobileNumber ? '' : user.mobileNumber}',
      mail: '${user.emailId}',
      country: '${!user.country ? '' : user.country}',
      city: '${!user.city ? '' : user.city}',
      region: '${!user.state ? '' : user.state}',
      ip:'${ipAddress}',
      domain:'${window.location.hostname}',
      role:'${user.role}',
      environment: '${endpoints.environment}',
      permissions:'${tokenDetail.permissions?.join(',') ?? ''}',
      subscriptionValid:'${tokenDetail.subscription.toUTCString()}',
      features: '${tokenDetail.features?.join(',') ?? ''}'
    },2000)`;
    let s: any = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://control.msg91.com/app/assets/widget/chat-widget.js";
    s.setAttribute('onload', msgpayload);
    document.getElementsByTagName('head')[0].appendChild(s);
  }


  removeMsg91Support(): void {
    const element: any = document.getElementsByTagName('head')[0].getElementsByTagName('script');
    let msgElement: any = null;

    for (let i = 0; i < element.length; i++) {
      if (element[i]['src'] === 'https://control.msg91.com/app/assets/widget/chat-widget.js') {
        msgElement = element[i];
      }
    }

    if (msgElement !== null) {
      document.getElementsByTagName('chat-widget')[0].remove();
      document.getElementsByTagName('head')[0].removeChild(msgElement);
    }

  }


  ngAfterViewInit(): void {
    this.headerNav.sideNav$.subscribe(x => {
      if (this.sideNav)
        this.sideNav.toggleButton();
    });
  }



  logout() {
    this.authService.logout();
  }

  toggledEvent() {

  }

}
