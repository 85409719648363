import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ToastService } from "@dmspro/notification";
import { AppRoleConstant, AppFeaturesConstant, DecodeToken } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/services/authentication.service";

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivateChild {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastService: ToastService
    ) { }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authenticationService.currentUserValue?.id) {
            if (this.authenticationService.currentUserValue.role === AppRoleConstant.admin || this.authenticationService.currentUserValue.role === AppRoleConstant.new)
                return true;
            else if (this.authenticationService.currentUserValue.role === AppRoleConstant.employee) {
                const tokenDetail = DecodeToken.getToken();
                const canEmployeeAccess = tokenDetail.features.includes(AppFeaturesConstant.USER_MANAGEMENT);
                return !!canEmployeeAccess;
            }
        }
        this.toastService.notification.warning('You are not authorized to access this page');
        this.router.navigate(['..'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}