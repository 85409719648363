<div class="box no-radius h-100 is-flex is-flex-direction-column width-280 side-nav-close has-background-white"
  #sideNav>
  <div class="columns">
    <div class="column is-narrow">
      <img src="../../../../assets/img_app/image 2 (Traced).png" width="35px" height="35px">
    </div>
    <div class="column">
      <strong> Dispatch Management</strong>

    </div>
  </div>
  <mat-nav-list *ngIf="isSubscribed else buynow">
    <app-side-nav-menu-item router-link="dashboard" menu-icon="dashboard"
      menu-text="Dashboard"></app-side-nav-menu-item>
    <p class="menu-label">
      Master Entries
    </p>
    <mat-expansion-panel [class.mat-elevation-z0]="true">
      <mat-expansion-panel-header>
        Configurations
      </mat-expansion-panel-header>
      <mat-nav-list>
        <!-- <app-side-nav-menu-item router-link="employee" *dmsAppFeatures="appFeatures.USER_MANAGEMENT" menu-icon="badge" menu-text="Employees"></app-side-nav-menu-item> -->
        <app-side-nav-menu-item router-link="customer" *dmsAppFeatures="appFeatures.DISPATCH_MANAGEMENT"
          menu-icon="group" menu-text="Brokers"></app-side-nav-menu-item>
        <app-side-nav-menu-item *dmsAppFeatures="appFeatures.DISPATCH_MANAGEMENT" router-link="foreigner" menu-icon="assignment_ind"
          menu-text="Payable Clients"></app-side-nav-menu-item>
        <app-side-nav-menu-item *dmsAppFeatures="appFeatures.DISPATCH_MANAGEMENT" router-link="shipper" menu-icon="forklift"
          menu-text="Shippers"></app-side-nav-menu-item>
        <app-side-nav-menu-item router-link="driver" menu-icon="directions_bike"
          menu-text="Drivers"></app-side-nav-menu-item>
        <app-side-nav-menu-item router-link="truck" menu-icon="local_shipping"
          menu-text="Trucks"></app-side-nav-menu-item>
        <app-side-nav-menu-item router-link="trailer" menu-icon="rv_hookup"
          menu-text="Trailer"></app-side-nav-menu-item>
      </mat-nav-list>
    </mat-expansion-panel>

    <p class="menu-label" *dmsAppFeatures="appFeatures.DISPATCH_MANAGEMENT">
      Dispatch Management
    </p>
    <app-side-nav-menu-item *dmsAppFeatures="appFeatures.DISPATCH_MANAGEMENT" router-link="dispatch"
      menu-icon="send_time_extension" menu-text="Dispatch Master"></app-side-nav-menu-item>
    <app-side-nav-menu-item *dmsAppFeatures="appFeatures.DISPATCH_MANAGEMENT" router-link="expenses"
      menu-icon="send_time_extension" menu-text="Expenses"></app-side-nav-menu-item>

    <p class="menu-label">
      Report Management
    </p>
    <app-side-nav-menu-item router-link="reports" menu-icon="flag_circle" menu-text="Reports"></app-side-nav-menu-item>
  </mat-nav-list>
  <div class="mt-3 is-flex-grow-1 has-text-grey-lighter">
    <div class="is-flex h-100 is-align-items-end">
      App Ver.: {{appVersion}}
    </div>
  </div>
</div>

<ng-template #buynow>
  <mat-nav-list>
    <p class="menu-la bel">
      Buy Subscription
    </p>
    <app-side-nav-menu-item router-link="dashboard" menu-icon="shopping_cart"
      menu-text="Pay Now"></app-side-nav-menu-item>
  </mat-nav-list>

</ng-template>