<div class="has-text-weight-bold is-size-4">
    SIGN IN
</div>
<div class="has-text-grey">
    Please sign-in to your account and start the adventure
</div>
<form [formGroup]="loginForm" class="mt-5" (submit)="loginAccount()">

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="username" placeholder="Ex. pat@example.com">
        <mat-error>
            {{controlErrorMessage('username')}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="bc w-100" appearance="outline">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password"
            placeholder="************">
        <mat-icon matSuffix class="pointer"
            (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
        <mat-error>
            {{controlErrorMessage('password')}}
        </mat-error>
    </mat-form-field>

    <button mat-raised-button [disabled]="(loginForm.invalid || isFormLoading)" type="submit" color="primary"
        class="w-100">
        Sign In
        <mat-icon *ngIf="isFormLoading">
            <mat-spinner color="accent" diameter="18">
            </mat-spinner>
        </mat-icon>
    </button>
</form>
<div class="is-flex is-flex-direction-row mt-3">
    <div class="is-flex-grow-1">
        <a routerLink="forget" style="display: inline-block;" class="ml-1">Need any help?</a>
    </div>
    <div>
        <a routerLink="signup" style="display: inline-block;" class="ml-1">New Here?</a>
    </div>
</div>