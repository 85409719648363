export class User {
    id!: string;
    name!: string;
    username!: string;
    userImage?: string;
    mobileNumber?: string;
    isMobileVerified!: boolean;
    emailId!: string;
    isEmailVerified!: boolean;
    role?: string;
    permissions?: string[];
    country: string;
    city: string;
    state: string;
    tokenResponse!: TokenResponse;
    companyResponse?: CompanyResponse;
}

export interface TokenResponse {
    refreshToken: string;
    token: string;
}

export interface CompanyResponse {
    id: string;
    name: string;
    logo: string;
    registrationNumber: string;
    email: string;
    city: string;
    state: string;
    pincode: string;
    address: string;
}