import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    driverEndpoints!: DriverEndpoints;
    regionEndpoints!: RegionEndpoints;
}

export class DriverEndpoints {
    create!: string;
    update!: string;
    getAllDriver!: string;
    getDriverById!: string;
    deleteDriver!: string;
    uploadImage!: string;
    uploadDocuments!: string;
    fetchDocuments!: string;
    updateDocument!: string;
    deleteDocument!: string;
    downloadCSV!: string;
    changeStatus!: string;
    driverBalanceDetail!: string;
    driverPayment!: string;
    resendActivationLink!: string;
}

export class RegionEndpoints {
    country!: string;
    state!: string;
    city!: string;
}

export const driverConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        driverEndpoints: {
            create: apiRoutes.user_management.driver.create,
            update: apiRoutes.user_management.driver.update,
            getAllDriver: apiRoutes.user_management.driver.getAll,
            getDriverById: apiRoutes.user_management.driver.getDetails,
            deleteDriver: apiRoutes.user_management.driver.delete,
            deleteDocument: apiRoutes.user_management.driver.deleteDocument,
            downloadCSV: apiRoutes.user_management.driver.downloadCSV,
            fetchDocuments: apiRoutes.user_management.driver.fetchDocuments,
            updateDocument: apiRoutes.user_management.driver.updateDocument,
            uploadDocuments: apiRoutes.user_management.driver.uploadDocuments,
            uploadImage: apiRoutes.user_management.driver.uploadImage,
            changeStatus: apiRoutes.user_management.driver.changeStatus,
            driverBalanceDetail: apiRoutes.user_management.driver.driverBalanceDetail,
            driverPayment: apiRoutes.user_management.driver.driverPayment,
            resendActivationLink: apiRoutes.user_management.driver.driverResendActivationLink
        },
        regionEndpoints: {
            country: apiRoutes.user_management.region.country,
            state: apiRoutes.user_management.region.state,
            city: apiRoutes.user_management.region.city
        }
    }
}