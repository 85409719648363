import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { CompanyDetailModel, DispatcherDetailModel, LoadConfirmDriverDetailModel, LoadShipperModel, PrintConfirmationDocumentService } from '@dmspro/print-documents';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-load-confirmation',
  templateUrl: './print-load-confirmation.component.html',
  styleUrls: ['./print-load-confirmation.component.scss']
})
export class PrintLoadConfirmationComponent {
  constructor(private printConfirmationDocumentService: PrintConfirmationDocumentService, private datePipe: DatePipe) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  async printDocument() {
    const company: CompanyDetailModel = {
      companyName: 'Company Name',
      address: 'Company Full Address with city, state, pincode',
      fax: '--',
      phone: '9876543210'
    };
    const dispatcher: DispatcherDetailModel = {
      dispatcherName: 'USER NAME',
      email: 'User Email',
      shipDate: 'MM/dd/yyyy',
      todayDate: 'MM/dd/yyyy',
      faxNumber: '--',
      loadNo: null,//Send if load number available otherwise null
      workOrderNumber: null,//Send if work order number available otherwise null
      phone: '9876543210'
    };

    const driver: LoadConfirmDriverDetailModel = {
      driverName: null, // Send if available,
      email: null, // Send if available,
      equipment: null, // Send if available,
      loadStatus: null, // Send if available,
      mobileNo: null, // Send if available,
      trailer: null, // Send if available,
      truck: null, // Send if available,
    }

    const loadShipper: LoadShipperModel[] = [
      {
        date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
        shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
        time: this.datePipe.transform(new Date(), 'HH:mm a'),
        description: 'asdaaaa',
        loadTbl: [{
          qty: "1",
          poNo: "",
          type: 'BOX',
          value: "200",
          weight: "2T"
        }],
        isLocationVerified: true,
        location: 'WR8P+J7J, Tagore Nagar, Civil Lines, Ludhiana, Punjab 141001',
        shipperName: 'Test Shipper 1',
        consignees: [
          {
            date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            time: this.datePipe.transform(new Date(), 'HH:mm a'),
            description: 'asdaaaa',
            loadTbl: [{
              qty: "1",
              poNo: "",
              type: 'BOX',
              value: "200",
              weight: "2T"
            }],
            isLocationVerified: true,
            location: 'WR8P+J7J, Tagore Nagar, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Consignee 1'
          },
          {
            date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            time: this.datePipe.transform(new Date(), 'HH:mm a'),
            description: 'asdaaaa',
            loadTbl: [{
              qty: "1",
              poNo: "",
              type: 'BOX',
              value: "200",
              weight: "2T"
            }],
            isLocationVerified: false,
            location: 'WR8P+J7J, Tagore Nagar11, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Consignee 2'
          }
        ]
      },
      {
        date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
        shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
        time: this.datePipe.transform(new Date(), 'HH:mm a'),
        description: 'asdaaaa',
        loadTbl: [{
          qty: "1",
          poNo: "",
          type: 'BOX',
          value: "200",
          weight: "2T"
        }],
        isLocationVerified: false,
        location: 'WR8P+J7J, Tagore Nagar11, Civil Lines, Ludhiana, Punjab 141001',
        shipperName: 'Test Shipper 2',
        consignees: [
          {
            date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            time: this.datePipe.transform(new Date(), 'HH:mm a'),
            description: 'asdaaaa',
            loadTbl: [{
              qty: "1",
              poNo: "",
              type: 'BOX',
              value: "200",
              weight: "2T"
            }],
            isLocationVerified: true,
            location: 'WR8P+J7J, Tagore Nagar, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Consignee 3'
          },
          {
            date: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            shippedDate: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            time: this.datePipe.transform(new Date(), 'HH:mm a'),
            description: 'asdaaaa',
            loadTbl: [{
              qty: "1",
              poNo: "",
              type: 'BOX',
              value: "200",
              weight: "2T"
            }],
            isLocationVerified: false,
            location: 'WR8P+J7J, Tagore Nagar11, Civil Lines, Ludhiana, Punjab 141001',
            shipperName: 'Test Consignee 4'
          }
        ]
      }
    ];

    const document = await this.printConfirmationDocumentService.getConfirmationDocument(company, dispatcher, driver, loadShipper);
    pdfMake.createPdf(document).open();
    // pdfMake.createPdf(document).getBase64((res) => {
    //   // Base 64 for browser for file or send mail
    //   console.log('data:application/pdf;base64, ' + res);
    // });
  }

}
