import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PrinterSettingModel } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { identityConfiguration } from "src/app/components/modules/configuration/identity.config.model";
import { subscriptionConfiguration } from "src/app/components/modules/configuration/settings.config.model";
import { ResultModel } from "src/models/result.models";
import { User } from "src/models/user.model";

@Injectable()
export class LoginService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private http: HttpClient) { }

    public login(username: string, password: string): Observable<ResultModel<User>> {
        return this.http.post<ResultModel<User>>(identityConfiguration.identityEndpoints.login, { username, password });
    }

    public register(name: string, username: string, password: string): Observable<ResultModel<string>> {
        return this.http.post<ResultModel<string>>(identityConfiguration.identityEndpoints.register, { name, emailId: username, password });
    }

    public forgetPassword(username: string): Observable<ResultModel<string>> {
        return this.http.post<ResultModel<string>>(identityConfiguration.identityEndpoints.forgetPassword, { username });
    }

    public verification(id: string, emailId: string, verification: string): Observable<ResultModel<string>> {
        const route = identityConfiguration.identityEndpoints.verification;
        return this.http.post<ResultModel<string>>(route, { id, emailId, code: verification });
    }

    public verifyUserAccount(code: string): Observable<ResultModel<string>> {
        const route = identityConfiguration.identityEndpoints.verifyUserAccount.replace('{code}', code);
        return this.http.get<ResultModel<string>>(route);
    }

    public printerSettings(): Observable<ResultModel<PrinterSettingModel>> {
        return this.http.get<ResultModel<PrinterSettingModel>>(subscriptionConfiguration.saleEndpoints.userManagementEndpoints.printerSettingsEndpoints.getsettings);
    }

    public verifyResetLink(email: string, code: string): Observable<ResultModel<string>> {
        return this.http.post<ResultModel<string>>(identityConfiguration.identityEndpoints.verifyResetLink, { email, code });
    }

    public resetPassowrd(id: string, code: string, emailId: string, password: string): Observable<ResultModel<string>> {
        return this.http.post<ResultModel<string>>(identityConfiguration.identityEndpoints.resetPassword, { id, code, emailId, password });
    }
}