<div>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <div>
            <span id="welcome">Welcome to</span>
            <span id="welcome-dms-solution">DMS Solution</span>
            <span class="is-size-6 has-text-white">Current Date</span>
            <span class="is-size-6 has-text-white has-text-weight-bold ml-2">{{currentDate | date: 'dd MMM, yyyy'}}</span>
        </div>
    </div>
    <div class="columns m-3">
        <img src="../../../assets/img_app/dms_svg_logo.svg">
        <span class="header-logo">Dispatch Management Solution</span>
    </div>
   
</div>