import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    trailerEndpoints!: TrailerEndpoints;
    trailerTypeEndponts!: TrailerTypeEndponts;
}

export class TrailerEndpoints {
    create!: string;
    update!: string;
    getAllTrailer!: string;
    getTrailerById!: string;
    deleteTrailer!: string;
    uploadImage!: string;
    uploadDocuments!: string;
    fetchDocuments!: string;
    updateDocument!: string;
    deleteDocument!: string;
    downloadCSV!: string;
    changeStatus!: string;
}

export class TrailerTypeEndponts {
    create!: string;
    getAll!: string;
}

export const trailerConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        trailerEndpoints: {
            create: apiRoutes.user_management.trailer.create,
            update: apiRoutes.user_management.trailer.update,
            getAllTrailer: apiRoutes.user_management.trailer.getAll,
            getTrailerById: apiRoutes.user_management.trailer.getById,
            deleteTrailer: apiRoutes.user_management.trailer.delete,
            changeStatus: apiRoutes.user_management.trailer.changeStatus,
            deleteDocument: apiRoutes.user_management.trailer.deleteDocument,
            downloadCSV: apiRoutes.user_management.trailer.downloadCSV,
            fetchDocuments: apiRoutes.user_management.trailer.fetchDocuments,
            updateDocument: apiRoutes.user_management.trailer.updateDocument,
            uploadDocuments: apiRoutes.user_management.trailer.uploadDocuments,
            uploadImage: apiRoutes.user_management.trailer.uploadImage
        },
        trailerTypeEndponts: {
            create: apiRoutes.user_management.trailerType.create,
            getAll: apiRoutes.user_management.trailerType.getAll,
        }
    },
}