<div class="has-text-weight-bold is-size-4" style="min-width: 350px;">
    Forget Password
</div>
<div class="has-text-grey mt-1">
    Please provide username to serve to best.
</div>
<form [formGroup]="forgetForm" class="mt-5" (submit)="forgetPassword()">

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="username" placeholder="Ex. pat@example.com">
        <mat-error>
            {{controlErrorMessage('username')}}
        </mat-error>
    </mat-form-field>

    <button mat-raised-button [disabled]="(forgetForm.invalid || isFormLoading)" type="submit" color="primary"
        class="w-100">
        Forget Password
        <mat-icon *ngIf="isFormLoading">
            <mat-spinner color="accent" diameter="18">
            </mat-spinner>
        </mat-icon>
    </button>
</form>
<div class="has-text-centered has-text-black mt-4">
    Change mind?
    <a routerLink=".." style="display: inline-block;" class="ml-1">Sign-in</a>
</div>