import { Component } from '@angular/core';
import { AdminPrintCustomerInvoiceService, InvoiceBasicDetailModel, InvoiceBillToModel, InvoiceChargesModel, InvoiceItemModel, InvoiceShipmentDetails } from '@dmspro/print-documents';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-customer-invoice',
  templateUrl: './print-customer-invoice.component.html',
  styleUrls: ['./print-customer-invoice.component.scss']
})
export class PrintCustomerInvoiceComponent {

  constructor(private adminPrintCustomerInvoiceService: AdminPrintCustomerInvoiceService) {
    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
  }

  async printDocument() {
    const invoiceBasicDetails: InvoiceBasicDetailModel = {
      date: '23-Mar, 2023',
      invoiceNo: 'L00001',
      issueTo: 'Abhishek Raheja'
    }
    const billTo: InvoiceBillToModel = {
      address: 'Hno 15 RD COLONY, St No 8, Old Suraj Nagari Abohar, Punjab 152116',
      email: 'raheja.abhi@hotmail.com',
      mobile: '9914964911',
      name: 'Abhishek Raheja'
    };

    const shipment: InvoiceShipmentDetails[] = [
      {
        shipperName: 'DIGI work, Ludhiana',
        address: 'Hno 15 RD COLONY, St No 8, Old Suraj Nagari Abohar, Punjab 152116',
        bolNumber: '123456',
        workOrderNumber: '123456',
        deliveredBy: 'Abhishek Raheja'
      }
    ];
    const consignees: string[] = ['Logistics', 'EGEN Solutions'];
    const detentionCharges: InvoiceChargesModel[] = [
      {
        reason: 'Detention Charges 1',
        amount: 100
      }
    ];
    const otherCharges: InvoiceChargesModel[] = [
      {
        reason: 'Other Charges 1',
        amount: 50
      },
      {
        reason: 'Other Charges 2',
        amount: 44
      }
    ];
    const advanceCharges: InvoiceChargesModel[] = [
      {
        reason: 'Advance Charges 1',
        amount: 30
      }
    ];

    const invoiceItems: InvoiceItemModel[] = [
      {
        type: 'Cartoon',
        fsc: 200,
        noOfUnit: 10,
        pds: 300,
        rate: 10,
        total: 1400
      }
    ];
    const testDocument = await this.adminPrintCustomerInvoiceService.getCustomerInvoiceInvoice(invoiceBasicDetails, billTo, shipment, consignees, invoiceItems, detentionCharges, otherCharges, advanceCharges);
    pdfMake.createPdf(testDocument).open();
  }
}
