<div class="modal" style="z-index: 150;" #newUserFormModal>
    <div class="modal-background"></div>
    <div class="modal-card">

        <section class="modal-card-body box">
            <div>
                <div class="upload-image-size has-background-white-ter is-relative">
                    <mat-icon class="div-center">
                        badge
                    </mat-icon>
                </div>
                <div class="mt-3 ml-3">
                    <span class="is-size-5 is-block">New User Info</span>
                    <span class="has-text-grey-light is-block mt-1 is-size-7">Please provide name and email address to create new account.</span>
                </div>
                <div class="mt-5">
                    <form [formGroup]="newUserForm" (ngSubmit)="onSubmit()">
                        <div>
                            <div class="is-flex">
                                <mat-form-field class="w-100 mx-1" appearance="outline">
                                    <mat-label>Name</mat-label>
                                    <input type="text" matInput formControlName="name" placeholder="Name">
                                    <mat-error>
                                        {{controlErrorMessage('name')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="is-flex mt-2">
                                <mat-form-field class="w-100 mx-1" appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input type="email" matInput formControlName="email"
                                        placeholder="example@email.com">
                                    <mat-error>
                                        {{controlErrorMessage('email')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>                          
                        </div>
                        <div class="mt-4">
                            <button type="submit" [disabled]="(newUserForm.invalid|| isFormLoading)"
                                mat-raised-button color="primary">Create Account
                                <mat-icon *ngIf="isFormLoading">
                                    <mat-spinner color="accent" diameter="18">
                                    </mat-spinner>
                                </mat-icon>
                            </button>
                            <button type="button" mat-raised-button class="ml-2" color="basic"
                                (click)="closeModal()">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    </div>
</div>