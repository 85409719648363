import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    saleEndpoints!: SaleEndpointConfiguration;
}

export class SaleEndpointConfiguration {
    reportsEndpoints!: ReportsEndpoints;
    functionEndpoints!: FunctionEndpoints;
}

export class ReportsEndpoints {
    orders!: string;
    payments!: string;
}

export class FunctionEndpoints {
    failedPayment!: string;
    retryPayment!: string;
}

export const superReportConfiguration: Configuration = {
    baseUrl: endpoints.identity,
    saleEndpoints: {
        reportsEndpoints: {
            orders: apiRoutes.sale_management.reports.orders,
            payments: apiRoutes.sale_management.reports.payments,
        },
        functionEndpoints: {
            failedPayment: apiRoutes.sale_management.functions.failedPayment,
            retryPayment: apiRoutes.sale_management.functions.retryPayment,
        }
    }
}