import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
  baseUrl!: string;
  userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
  loadEndpoints!: LoadEndpoints;
  loadTypeEndpoints!: LoadTypeEndponts
  customerEndpoints!: CustomerEndpoints;
  foreignerEndpoints!: ForeignerEndpoints;
  shipperEndpoints!: ShipperEndpoints;
  driverEndpoints!: DriverEndpoints;
  communicationEndpoints!: CommunicationEndpoints;
  loadCommunicationEndpoints!: LoadCommunicationEndpoints;
}

export class LoadEndpoints {
  create!: string;
  update!: string;
  assign!: string;
  status!: string;
  getloads!: string;
  getloadbyid!: string;
  getnextstatus!: string;
  getdrivers!: string;
  gettruck!: string;
  gettrailer!: string;
  gettrailertype!: string;
  getloadstatus!: string;
  getworkorderform!: string;
  createworkorder!: string;
  getworkorder!: string;
  uploadworkdocument!: string;
  completeLoadStatus!: string;
  getloadforupdate!: string;
  getcompletedloadforupdate!: string;
  getreinvoices!: string;
  getinvoice!: string;
  loadbalancedetail!: string;
  loadpayment!: string;
  fetchdocuments!: string;
  uploadDocuments!: string;
  updateDocument!: string;
  deleteDocument!: string;
}
export class DriverEndpoints {
  getDriversByLoadId!: string;
}
export class LoadTypeEndponts {
  create!: string;
  getAll!: string;
}
export class CustomerEndpoints {
  getAllCustomer!: string;
  getCustomerById!: string;
}
export class ForeignerEndpoints {
  create!: string;
  getAllForeigner!: string;
  getForeignerById!: string;
  changeStatus!: string;
  uploadImage!: string;
  getterm!: string;
  createterm!: string;
}

export class CommunicationEndpoints {
  endpoint!: string;
  join!: string;
  remove!: string;
}

export class LoadCommunicationEndpoints {
  connectionEndpoint!: string;
}

export class ShipperEndpoints {
  getAllShipper!: string;
  getShipperById!: string;
}


export const dispatchConfiguration: Configuration = {
  baseUrl: endpoints.user_management,
  userEndpoints: {
    loadEndpoints: {
      create: apiRoutes.user_management.load.create,
      assign: apiRoutes.user_management.load.assign,
      getdrivers: apiRoutes.user_management.load.getdrivers,
      getloads: apiRoutes.user_management.load.getloads,
      getloadbyid: apiRoutes.user_management.load.getloadbyid,
      getnextstatus: apiRoutes.user_management.load.getnextstatus,
      gettrailer: apiRoutes.user_management.load.gettrailers,
      gettrailertype: apiRoutes.user_management.load.gettrailertype,
      gettruck: apiRoutes.user_management.load.gettrucks,
      status: apiRoutes.user_management.load.status,
      getloadstatus: apiRoutes.user_management.load.getloadstatus,
      getworkorderform: apiRoutes.user_management.load.getworkorderform,
      getworkorder: apiRoutes.user_management.load.getworkorder,
      createworkorder: apiRoutes.user_management.load.createworkorder,
      uploadworkdocument: apiRoutes.user_management.load.uploadworkdocument,
      completeLoadStatus: apiRoutes.user_management.load.completeloadstatus,
      getloadforupdate: apiRoutes.user_management.load.getloadforupdate,
      update: apiRoutes.user_management.load.update,
      getcompletedloadforupdate: apiRoutes.user_management.load.getcompletedloadforupdate,
      getreinvoices: apiRoutes.user_management.load.getreinvoices,
      getinvoice: apiRoutes.user_management.load.getinvoice,
      loadbalancedetail: apiRoutes.user_management.load.loadbalancedetail,
      loadpayment: apiRoutes.user_management.load.loadpayment,
      deleteDocument: apiRoutes.user_management.load.deleteDocument,
      fetchdocuments: apiRoutes.user_management.load.fetchdocuments,
      updateDocument: apiRoutes.user_management.load.updateDocument,
      uploadDocuments: apiRoutes.user_management.load.uploadDocuments
    },
    loadTypeEndpoints: {
      create: apiRoutes.user_management.loadType.create,
      getAll: apiRoutes.user_management.loadType.getAll
    },
    customerEndpoints: {
      getAllCustomer: apiRoutes.user_management.customer.getAll,
      getCustomerById: apiRoutes.user_management.customer.getDetails
    },
    foreignerEndpoints: {
      create: apiRoutes.user_management.foreigner.create,
      getAllForeigner: apiRoutes.user_management.foreigner.getAll,
      getForeignerById: apiRoutes.user_management.foreigner.getDetails,
      changeStatus: apiRoutes.user_management.foreigner.changeStatus,
      uploadImage: apiRoutes.user_management.foreigner.uploadImage,
      getterm: apiRoutes.user_management.foreigner.getterm,
      createterm: apiRoutes.user_management.foreigner.createterm
    },
    shipperEndpoints: {
      getAllShipper: apiRoutes.user_management.shipper.getAll,
      getShipperById: apiRoutes.user_management.shipper.getDetails,
    },
    driverEndpoints: {
      getDriversByLoadId: apiRoutes.user_management.driver.getDriversByLoadId
    },
    communicationEndpoints: {
      endpoint: apiRoutes.communication.connect.endpoint,
      join: apiRoutes.communication.connect.join,
      remove: apiRoutes.communication.connect.remove,
    },
    loadCommunicationEndpoints: {
      connectionEndpoint: apiRoutes.authorization.communicationconnection,
    }
  }
}
