import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class IPService {

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private http: HttpClient) {

    }

    public getIpAddress(): Observable<any> {
        return this.http.get<any>('http://api.ipify.org/?format=json');
    }

}