<!-- Document for help -->
<!-- http://pdfmake.org/playground.html -->


<div>
    <span class="is-block">Print Invoice</span>
    <app-print-invoice></app-print-invoice>
</div>

<div>
    <span class="is-block">Print Shipment Invoice</span>
    <app-print-shipment-invoice></app-print-shipment-invoice>
</div>

<div>
    <span class="is-block">Print Template</span>
    <app-print-template></app-print-template>
</div>
<div>
    <span class="is-block">Print Customer Invoice</span>
    <app-print-customer-invoice></app-print-customer-invoice>
</div>

<div>
    <span class="is-block">Print Reports</span>
    <app-print-report></app-print-report>
</div>
<div>
  <span class="is-block">Print Quotation</span>
  <app-print-quotation></app-print-quotation>
</div>
<div>
  <span class="is-block">Print Quotation</span>
  <app-print-load-confirmation></app-print-load-confirmation>
</div>
