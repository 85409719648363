import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoleConstant } from '@dmspro/shared-lib';
import { AuthenticationService } from 'src/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthenticationService, private router: Router) {

  }

  ngOnInit(): void {
    this.authService.currentUserSubject.subscribe(x => {
      if (x?.id) {
        switch (x.role) {
          case AppRoleConstant.super_admin:
            this.router.navigate(['/super']);
            break;
          default:
            this.router.navigate(['/account']);
            break;
        }
      }
    });
  }

}
