import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    identityEndpoints!: IdentityEndpointConfiguration;
    subscriptionEndpoints!: SubscriptionEndpoints;
    userManagementEndpoints!: UserManagementEndpointsConfiguration;
}

export class IdentityEndpointConfiguration {
    customerEndpoints!: CustomerEndpoints;
    userProfileEndpoints!: UserProfileEndpoints;
}

export class SubscriptionEndpoints {
    currentsubscription!: string;
    getinvoices!: string;
}

export class UserProfileEndpoints {
    profile!: string;
    updateProfile!: string;
    company!: string;
    updateCompany!: string;
    notification!: string;
    updateNotification!: string;
    changePassowrd!: string;
    changeProfile!: string;
    changeCompanyLogo!: string;
}


export class UserManagementEndpointsConfiguration {
    printerSettingsEndpoints!: PrinterSettingsEndpoints;
    codeSettingsEndpoints!: CodeSettingsEndpoints;
}

export class PrinterSettingsEndpoints {
    updateprint!: string;
    getsettings!: string;
    uploadlogo!: string;
}
export class CodeSettingsEndpoints {
    getcodesettings!: string;
    updatecodesettings!: string;
}

export class CustomerEndpoints {
    create!: string;
    update!: string;
    getAllCustomer!: string;
    getCustomerById!: string;
    activateCustomer!: string;
    deActivateCustomer!: string;
    uploadImage!: string;
}

export const superCustomerConfiguration: Configuration = {
    baseUrl: endpoints.identity,
    identityEndpoints: {
        customerEndpoints: {
            create: apiRoutes.super_admin.customer.create,
            update: apiRoutes.super_admin.customer.update,
            getAllCustomer: apiRoutes.super_admin.customer.getAll,
            getCustomerById: apiRoutes.super_admin.customer.getDetails,
            activateCustomer: apiRoutes.super_admin.customer.activate,
            deActivateCustomer: apiRoutes.super_admin.customer.de_activate,
            uploadImage: apiRoutes.super_admin.customer.uploadImage
        },
        userProfileEndpoints: {
            profile: apiRoutes.identityUser.mastermanageprofile.profile,
            updateProfile: apiRoutes.identityUser.mastermanageprofile.updateProfile,
            company: apiRoutes.identityUser.mastermanageprofile.company,
            updateCompany: apiRoutes.identityUser.mastermanageprofile.updateCompany,
            notification: apiRoutes.identityUser.mastermanageprofile.notification,
            updateNotification: apiRoutes.identityUser.mastermanageprofile.updateNotification,
            changePassowrd: apiRoutes.identityUser.mastermanageprofile.changePassowrd,
            changeCompanyLogo: apiRoutes.identityUser.mastermanageprofile.changeCompanyLogo,
            changeProfile: apiRoutes.identityUser.mastermanageprofile.changeProfile
        }
    },
    subscriptionEndpoints: {
        currentsubscription: apiRoutes.sale_management.subscription.mastercurrentsubscription,
        getinvoices: apiRoutes.sale_management.subscription.mastergetinvoices
    },
    userManagementEndpoints: {
        printerSettingsEndpoints:{
            updateprint: apiRoutes.user_management.masterprintercodeSettings.updateprint,
            getsettings:apiRoutes.user_management.masterprintercodeSettings.getsettings,
            uploadlogo: apiRoutes.user_management.masterprintercodeSettings.uploadlogo
        },
        codeSettingsEndpoints: {
            getcodesettings: apiRoutes.user_management.masterprintercodeSettings.getcodesettings,
            updatecodesettings: apiRoutes.user_management.masterprintercodeSettings.updatecodesettings
        }
    }
}