import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResultModel } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { notificationConfiguration } from "src/app/components/modules/configuration/notification.config.model";
import { NotificationResponseModel } from "src/models/notification.response.model";

@Injectable()
export class NotificationService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };
    constructor(private client: HttpClient) {

    }

    getNotification(): Observable<ResultModel<NotificationResponseModel[]>> {
        return this.client.get<ResultModel<NotificationResponseModel[]>>(notificationConfiguration.userEndpoints.notificationEndpoints.getNotifications);
    }
    
}