import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@dmspro/notification';
import { firstValueFrom, map } from 'rxjs';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-verify-user-account',
  templateUrl: './verify-user-account.component.html',
  styleUrls: ['./verify-user-account.component.scss']
})
export class VerifyUserAccountComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private loginService: LoginService,
    private toastService: ToastService,
    private router: Router) {

  }

  async ngOnInit(): Promise<void> {

    const params$ = this.route.queryParams.pipe(map((params: any) => params));
    const params = await firstValueFrom(params$);
    const { code } = params;
    this.loginService.verifyUserAccount(code).subscribe({
      next: (res) => {
        if (res.success) {
          this.router.navigate(["/login"]);
          this.toastService.notification.success("Verification Completed");
        }
      },
      error: (err) => {
        this.router.navigate(["/login"]);
        this.toastService.notification.error(err.message);
      }
    });
  }
}
