import { Component } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-template',
  templateUrl: './print-template.component.html',
  styleUrls: ['./print-template.component.scss']
})
export class PrintTemplateComponent {
  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  printDocument() {
    const documentDefinition = this.getDocument();
    pdfMake.createPdf(documentDefinition).print();
  }

  private getDocument(): any {
    return {
      header: {
        text: 'hello'
      },
      content: {},
      footer: {},
      styles: {},
      images: {}
    };
  }

}
