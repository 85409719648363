import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from 'src/interceptors/error.interceptor';
import { JwtInterceptor } from 'src/interceptors/jwt-login.interceptor';
import { MainpageComponent } from './components/modules/landingpage/mainpage/mainpage.component';
import { NavHeaderComponent } from './components/shared/nav-header/nav-header.component';
import { SideNavComponent } from './components/shared/side-nav/side-nav.component';
import { LoginComponent } from './components/login/login.component';
import { LeftControlComponent } from './components/login/left-control/left-control.component';
import { RightControlComponent } from './components/login/right-control/right-control.component';
import { SignInComponent } from './components/login/right-control/sign-in/sign-in.component';
import { SignUpComponent } from './components/login/right-control/sign-up/sign-up.component';
import { ForgetComponent } from './components/login/right-control/forget/forget.component';
import { RecoveryComponent } from './components/login/right-control/recovery/recovery.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginService } from 'src/services/login.service';
import { SuperNavHeaderComponent } from './components/shared/super-nav-header/super-nav-header.component';
import { SuperSideNavComponent } from './components/shared/super-side-nav/super-side-nav.component';
import { AccountVerificationComponent } from './components/login/right-control/account-verification/account-verification.component';
import { DmsNotificationModule } from '@dmspro/notification';
import { DmsCustomerModule } from '@dmspro/customer';
import { DmsDriverModule } from '@dmspro/driver';
import { DMSDashboardModule } from '@dmspro/dashboard';
import { DmsSuperCustomerModule } from '@dmspro/super-customer';
import { DmsWebsiteModule } from '@dmspro/website';
import { DmsSuperSubscriptionModule } from '@dmspro/super-subscription';
import { DmsPaymentModule } from '@dmspro/payment';
import { DmsShipperModule } from '@dmspro/shipper';
import { DmsTruckModule } from '@dmspro/truck';
import { DmsTrailerModule } from '@dmspro/trailer';
import { DmsSuperReportsModule } from '@dmspro/super-reports';
import { DmsSharedLibModule } from '@dmspro/shared-lib';
import { DmsExpensesModule} from '@dmspro/expenses';
import { paymentConfiguration } from './components/modules/configuration/payment.config.model';
import { customerConfiguration } from './components/modules/configuration/customer.config.model';
import { driverConfiguration } from './components/modules/configuration/driver.config.model';
import { dashboardConfiguration } from './components/modules/configuration/dashboard.config.model';
import { superCustomerConfiguration } from './components/modules/configuration/super-admin/customer-super.config.model';
import { saleConfiguration } from './components/modules/configuration/home/home.config.mode';
import { superSaleConfiguration } from './components/modules/configuration/super-admin/sale-super.config.model';
import { shipperConfiguration } from './components/modules/configuration/shipper.config.model';
import { truckConfiguration } from './components/modules/configuration/truck.config.model';
import { trailerConfiguration } from './components/modules/configuration/trailer.config.model';
import { superReportConfiguration } from './components/modules/configuration/super-admin/reports-super.config.model';
import { sharedConfiguration } from './components/modules/configuration/shared.config.model';
import { DmsLoadModule } from '@dmspro/load';
import { dispatchConfiguration } from './components/modules/configuration/load.config.model';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './modules/app-material.module';
import { SideNavMenuItemComponent } from './components/shared/side-nav-menu-item/side-nav-menu-item.component';
import { subscriptionConfiguration } from './components/modules/configuration/settings.config.model';
import { DmsSettingsModule } from '@dmspro/settings';
import { TestComponent } from './components/test/test.component';
import { PrintInvoiceComponent } from './components/test/print-invoice/print-invoice.component';
import { PrintShipmentInvoiceComponent } from './components/test/print-shipment-invoice/print-shipment-invoice.component';
import { PrintTemplateComponent } from './components/test/print-template/print-template.component';
import { foreignerConfiguration } from './components/modules/configuration/foreigner.config.model';
import { DmsForeignerModule } from '@dmspro/foreigner';
import { NewUserComponent } from './components/shared/nav-header/new-user/new-user.component';
import { UserManagementService } from 'src/services/user-management.service';
import { DmsReportsModule } from '@dmspro/reports';
import { reportConfiguration } from './components/modules/configuration/report-config.model';
import { PrintCustomerInvoiceComponent } from './components/test/print-customer-invoice/print-customer-invoice.component';
import { PrintReportComponent } from './components/test/print-report/print-report.component';
import { DriverPaymentComponent } from './components/shared/nav-header/driver-payment/driver-payment.component';
import { PrintQuotationComponent } from './components/test/print-quotation/print-quotation.component';
import { expensesConfiguration } from './components/modules/configuration/expenses.config.model';
import { RequestDemoService } from 'src/services/request-demo.service';
import { NotificationService } from 'src/services/notificaiton.service';
import { IPService } from 'src/services/ip.service';
import { VerifyUserAccountComponent } from './components/login/right-control/verify-user-account/verify-user-account.component';
import { PrintLoadConfirmationComponent } from './components/test/print-load-confirmation/print-load-confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    MainpageComponent,
    NavHeaderComponent,
    SideNavComponent,
    LoginComponent,
    LeftControlComponent,
    RightControlComponent,
    SignInComponent,
    SignUpComponent,
    ForgetComponent,
    RecoveryComponent,
    SuperNavHeaderComponent,
    SuperSideNavComponent,
    AccountVerificationComponent,
    SideNavMenuItemComponent,
    TestComponent,
    PrintInvoiceComponent,
    PrintShipmentInvoiceComponent,
    PrintTemplateComponent,
    NewUserComponent,
    PrintCustomerInvoiceComponent,
    PrintReportComponent,
    DriverPaymentComponent,
    PrintQuotationComponent,
    VerifyUserAccountComponent,
    PrintLoadConfirmationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    DmsNotificationModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    DmsCustomerModule.forRoot(customerConfiguration),
    DmsDriverModule.forRoot(driverConfiguration),
    DMSDashboardModule.forRoot(dashboardConfiguration),
    DmsSuperCustomerModule.forRoot(superCustomerConfiguration),
    DmsWebsiteModule.forRoot(saleConfiguration),
    DmsSuperSubscriptionModule.forRoot(superSaleConfiguration),
    DmsPaymentModule.forRoot(paymentConfiguration),
    DmsShipperModule.forRoot(shipperConfiguration),
    DmsTruckModule.forRoot(truckConfiguration),
    DmsTrailerModule.forRoot(trailerConfiguration),
    DmsLoadModule.forRoot(dispatchConfiguration),
    DmsSuperReportsModule.forRoot(superReportConfiguration),
    DmsSharedLibModule.forRoot(sharedConfiguration),
    DmsSettingsModule.forRoot(subscriptionConfiguration),
    DmsForeignerModule.forRoot(foreignerConfiguration),
    DmsReportsModule.forRoot(reportConfiguration),
    DmsExpensesModule.forRoot(expensesConfiguration)
  ],
  providers: [
    LoginService,
    UserManagementService,
    RequestDemoService,
    NotificationService,
    IPService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
