import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
  baseUrl!: string;
  userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
  shipperEndpoints!: ShipperEndpoints;
  regionEndpoints!: RegionEndpoints;
}

export class ShipperEndpoints {
  create!: string;
  update!: string;
  getAllShipper!: string;
  getShipperById!: string;
  deleteShipper!: string;
  uploadImage!: string;
  uploadDocuments!: string;
  fetchDocuments!: string;
  updateDocument!: string;
  deleteDocument!: string;
  downloadCSV!: string;
  changeStatus!: string;
}

export class RegionEndpoints {
  country!: string;
  state!: string;
  city!: string;
}

export const shipperConfiguration: Configuration = {
  baseUrl: endpoints.user_management,
  userEndpoints: {
    shipperEndpoints: {
      create: apiRoutes.user_management.shipper.create,
      update: apiRoutes.user_management.shipper.update,
      getAllShipper: apiRoutes.user_management.shipper.getAll,
      getShipperById: apiRoutes.user_management.shipper.getDetails,
      deleteShipper: apiRoutes.user_management.shipper.delete,
      changeStatus: apiRoutes.user_management.shipper.changeStatus,
      deleteDocument: apiRoutes.user_management.shipper.deleteDocument,
      downloadCSV: apiRoutes.user_management.shipper.downloadCSV,
      fetchDocuments: apiRoutes.user_management.shipper.fetchDocuments,
      updateDocument: apiRoutes.user_management.shipper.updateDocument,
      uploadDocuments: apiRoutes.user_management.shipper.uploadDocuments,
      uploadImage: apiRoutes.user_management.shipper.uploadImage,
    },
    regionEndpoints: {
      country: apiRoutes.user_management.region.country,
      state: apiRoutes.user_management.region.state,
      city: apiRoutes.user_management.region.city
    }
  },
};
