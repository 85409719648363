export const endpoints = {
  base_address: "https://dev-dmsserver.azurewebsites.net",
  identity: "https://dev-dmsserver.azurewebsites.net//identity/api",
  user_management: "https://dev-dmsserver.azurewebsites.net/user/api",
  sale_management: "https://dev-dmsserver.azurewebsites.net/sale/api",
  communication: "https://dev-dmsserver.azurewebsites.net/communication/api",
  version1: "v1",
  environment: "dev"
};

export const environment = {
  production: false,
};

export const application = {
  version: '0.0.5'
}
