import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastService } from '@dmspro/notification';
import { ControlValidation, CustomControlForm } from '@dmspro/shared-lib';
import { firstValueFrom, map } from 'rxjs';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  isFormLoading: boolean = false;
  private id: string = null;
  private emailId: string = null;
  private code: string = null;
  private platForm: string = null;
  resetForm: FormGroup;
  showPassword = false;
  showConfPassword = false;

  constructor(private route: ActivatedRoute,
    private loginService: LoginService,
    private toastService: ToastService,
    private router: Router) {
    this.resetForm = new FormGroup({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confPassword: new FormControl(null, [Validators.required, Validators.minLength(8)])
    })
  }


  async ngOnInit(): Promise<void> {
    const params$ = this.route.queryParams.pipe(map((params: any) => params));
    const params = await firstValueFrom(params$);
    const { id, emailId, code, platform } = params;
    this.id = id;
    this.emailId = emailId;
    this.code = code;
    this.platForm = platform;
    this.loginService.verifyResetLink(emailId, code).subscribe({
      next: (res) => {
        if (res.success) {
          return;
        }
      },
      error: (err) => {
        this.toastService.notification.error('Invalid request.');
        this.router.navigate(['/login']);
      }
    });
  }

  controlErrorMessage(controlName: string): string {
    return ControlValidation.ControlError(this.resetForm.controls[controlName] as any);
  }

  resetPassword() {
    const controls = this.resetForm.controls;
    const newPassword = controls['newPassword'].value;
    const confPassword = controls['confPassword'].value;
    if (newPassword !== confPassword) {
      return this.toastService.notification.error('Password not matched');
    }
    this.loginService.resetPassowrd(this.id, this.code, this.emailId, newPassword).subscribe({
      next: (res) => {
        let routeLink: string = '/login';
        if (!!this.platForm) {
          switch (this.platForm) {
            case 'm':
              routeLink = '/home';
              break;
            default:
              routeLink = '/login';
              break;
          }
        }
        this.router.navigate([routeLink]);
        this.toastService.notification.success('Password reset successfully');
      },
      error: (err) => {
        this.toastService.notification.error(err.message);
        this.resetForm.reset();
        CustomControlForm.resetForm(this.resetForm);
      }
    })
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  public toggleConfPasswordVisibility(): void {
    this.showConfPassword = !this.showConfPassword;
  }

}
