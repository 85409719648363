<div>
    <mat-toolbar class="is-flex is-flex-direction-row has-background-white box is-radiusless p-0">
        <button mat-icon-button class="ml-2" type="button" (click)="sideNavClick()"
            aria-label="Example icon-button with menu icon">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="is-align-self-center is-flex-grow-1 ">
            <input type="text" class="input is-small mobile-screen is-rounded" placeholder="search"
                style="width: 250px;">
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menuNotification">
            <mat-icon>notifications</mat-icon>
        </button>
        <mat-menu #menuNotification="matMenu">
            <span class="is-block p-4">
                Under Construction...
            </span>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <figure class="image is-32x32" *ngIf="!!userInformation.userImage else userimagetemplate">
                <img class="is-rounded" alt="user" src="{{userInformation.userImage}}">
            </figure>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </mat-toolbar>
</div>

<ng-template #userimagetemplate>
    <mat-icon>account_circle</mat-icon>
</ng-template>