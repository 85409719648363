import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    saleEndpoints!: SaleEndpointConfiguration;
}

export class SaleEndpointConfiguration {
    baseUrl!: string;
    subscriptionEndpoints!: SubscriptionEndpoints;
    identityEndpointConfiguration!: IdentityEndpointConfiguration;
    billingEndpoints!: BillingEndpoints;
}


export class IdentityEndpointConfiguration {
    regerateToken!: string;
}

export class SubscriptionEndpoints {
    getSubscription!: string;
    getSubscriptionById!: string;
    fakePayment!: string;
    getPaymentLink!: string;
    checkPayment!: string;
}

export class BillingEndpoints {
    getAddress!: string;
    createAddress!: string;
    updateAddress!: string;
}

export const paymentConfiguration: Configuration = {
    saleEndpoints: {
        baseUrl: endpoints.sale_management,
        subscriptionEndpoints: {
            getSubscription: apiRoutes.sale_management.opensubscription.getsubscriptions,
            getSubscriptionById: apiRoutes.sale_management.subscription.getSubscriptionById,
            fakePayment: apiRoutes.sale_management.subscription.fakePayment,
            getPaymentLink: apiRoutes.sale_management.subscription.getPaymentLink,
            checkPayment: apiRoutes.sale_management.subscription.subscriptionPayment,
        },
        identityEndpointConfiguration: {
            regerateToken: apiRoutes.authorization.regenerateToken
        },
        billingEndpoints: {
            createAddress: apiRoutes.identityUser.paymentBillingAddress.createAddress,
            getAddress: apiRoutes.identityUser.paymentBillingAddress.getAddress,
            updateAddress: apiRoutes.identityUser.paymentBillingAddress.updateAddress
        }
    },
}