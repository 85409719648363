
import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    identity!: IdentityEndpointConfiguration;
    saleEndpoints!: SaleEndpointConfiguration;
    userEndpoint!: UserEndpointConfiguration;
}

export class SaleEndpointConfiguration {
    baseUrl!: string;
    subscriptionEndpoints!: SubscriptionEndpoints;

}

export class IdentityEndpointConfiguration {
    baseUrl!: string;
    companyEndpoints!: CompanyEndpoints;
    regerateToken!: string;
}


export class UserEndpointConfiguration {
    baseUrl!: string;
    dashboardEndpoints!: DashboardEndpoints;
    
}

export class SubscriptionEndpoints {
    getSubscription!: string;
}

export class CompanyEndpoints {
    create!: string;
}

export class DashboardEndpoints {
    headers!: string;
    activeLoad!: string;
    loadDetails!: string;
    burndownchart!: string;
    userFailedPayment!:string;
    retryPayment!:string;
}


export const dashboardConfiguration: Configuration = {
    identity: {
        baseUrl: endpoints.identity,
        companyEndpoints: {
            create: apiRoutes.company.create
        },
        regerateToken: apiRoutes.authorization.regenerateToken
    },
    saleEndpoints: {
        baseUrl: endpoints.sale_management,
        subscriptionEndpoints: {
            getSubscription: apiRoutes.sale_management.opensubscription.getsubscriptions
        },
      
    },
    userEndpoint: {
        baseUrl: endpoints.user_management,
        dashboardEndpoints: {
            headers: apiRoutes.user_management.dashboard.headers,
            activeLoad: apiRoutes.user_management.dashboard.activeLoad,
            loadDetails: apiRoutes.user_management.dashboard.loadDetails,
            burndownchart:  apiRoutes.user_management.dashboard.burndownchart,
            userFailedPayment:apiRoutes.user_management.reports.getUserFailedPayments,
            retryPayment:apiRoutes.sale_management.functions.retryPayment
        }
    }
}
