import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppFeaturesConstant, DecodeToken } from '@dmspro/shared-lib';
import { AuthenticationService } from 'src/services/authentication.service';
import { application } from "src/environments/environment";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  appFeatures = AppFeaturesConstant;
  appVersion = application.version;

  @ViewChild('sideNav') sideNav: ElementRef = {} as any;
  isToggled: boolean = true;

  isSideNavVisible: boolean = true;

  isSubscribed: boolean = false;

  constructor(private render: Renderer2, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isSideNavVisible = !!this.authService.currentUserValue?.companyResponse?.id;
    const tokenDetail = DecodeToken.getToken();
    if (tokenDetail?.subscription) {
      this.isSubscribed = tokenDetail.subscription >= new Date();
    }
  }

  toggleButton() {
    const classContains = this.sideNav.nativeElement.classList.contains('side-nav-open');
    if (classContains) {
      this.render.removeClass(this.sideNav.nativeElement, 'side-nav-open');
      this.render.addClass(this.sideNav.nativeElement, 'side-nav-close');
    }
    else {
      this.render.addClass(this.sideNav.nativeElement, 'side-nav-open');
      this.render.removeClass(this.sideNav.nativeElement, 'side-nav-close');
    }
  }

  menuListVisible(element: any) {
    const classContains = element.classList.contains('close-list');
    if (classContains) {
      this.render.removeClass(element, 'close-list');
      this.render.addClass(element, 'open-list');
    }
    else {
      this.render.removeClass(element, 'open-list');
      this.render.addClass(element, 'close-list');
    }
  }
}
