import { Component } from '@angular/core';
import { InvoiceTableBodyItemsModel } from '../models/invoice-table-body-item.model';
import { InvoiceDetails, InvoiceToModel } from '../models/invoice-to.model';
import { InvoiceTotalModel } from '../models/invoice-total.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent {

  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public printDocument() {
    const invoiceTo = <InvoiceToModel>{ name: 'Abhishek Raheja', mobile: '9914964911', fullAddress: 'Hno 15, RD Colony, St No 8, Old Suraj Nagari, Abohar, Punjab 152116' };
    const invoiceDetails = <InvoiceDetails>{ invoiceNumber: '123456', invoiceDate: '30-Jan-2023' };
    const invoiceItems: InvoiceTableBodyItemsModel[] = [
      {
        id: '1',
        amount: 300,
        description: 'Test Desc',
        itemCode: '1001',
        price: 300,
        qty: 1
      }
    ];
    const subTotal = invoiceItems.reduce((a, b) => a + b.amount, 0);
    const taxPercentage = 10;
    const taxAmount = (subTotal * taxPercentage) / 100;
    const invoiceTotal = <InvoiceTotalModel>{
      subtotal: subTotal,
      taxAmount: taxAmount,
      taxName: 'GST',
      taxPercentage: taxPercentage
    };

    const documentDefinition = this.getDocument(invoiceTo, invoiceDetails, invoiceTotal, invoiceItems);
    pdfMake.createPdf(documentDefinition).print();
  }

  private getDocument(invoiceTo: InvoiceToModel, invoiceDetails: InvoiceDetails, invoiceTotal: InvoiceTotalModel, invoiceItemsDetails: InvoiceTableBodyItemsModel[]): any {
    return {
      //  watermark: { image: 'test watermark', fontSize: 20 },
      header: this.getHeader(),
      content: [
        {
          style: 'invoiceInfo',
          columns: this.getInvoiceHeader(invoiceTo, invoiceDetails),
        },
        {
          table: {
            margin: [0, 20, 0, 20],
            headerRows: 1,
            widths: ['auto', '*', 'auto', 30, 'auto'],
            body: this.getTableBody(invoiceItemsDetails)
          },
          layout: 'headerLineOnly',
        },
        {
          stack: this.getTableTotal(invoiceTotal)
        },
      ],
      footer: this.getFooter(),
      styles: this.getStyle(),
      images: this.getImages()
    };
  }

  private getInvoiceHeader(model: InvoiceToModel, invoice: InvoiceDetails): any[] {
    return [
      // Invoice Left side header
      [
        {
          color: 'gray',
          fontSize: 11,
          text: 'INVOICE TO'
        },
        {
          fontSize: 18,
          text: model.name
        },
        {
          margin: [0, 10, 0, 0],
          fontSize: 13,
          color: 'gray',
          text: model.fullAddress
        },
        {
          color: 'gray',
          margin: [0, 10, 0, 0],
          fontSize: 13,
          text: model.mobile
        }
      ],
      {
        width: '*',
        text: ''
      },
      // Invoice Right Side header
      [
        {
          color: '#0D6EFD',
          fontSize: 29,
          text: 'INVOICE'
        },
        {
          margin: [0, 20, 0, 0],
          columns: [
            {
              color: '#8094AE',
              alignment: 'right',
              width: '*',
              text: 'INVOICE ID'
            },
            {
              color: '#8094AE',
              text: ':',
              margin: [5, 0, 5, 0],
              width: 'auto',
            },
            {
              color: '#8094AE',
              width: '*',
              text: invoice.invoiceNumber
            }
          ]
        },
        {
          columns: [
            {
              color: '#8094AE',
              alignment: 'right',
              width: '*',
              text: 'DATE'
            },
            {
              color: '#8094AE',
              text: ':',
              margin: [5, 0, 5, 0],
              width: 'auto',
            },
            {
              color: '#8094AE',
              width: '*',
              text: invoice.invoiceDate
            }
          ]
        },


      ]
    ];
  }

  private getTableBody(invoiceDetails: InvoiceTableBodyItemsModel[]) {
    const body = [
      this.getTableHeader()
    ];
    if (invoiceDetails) {
      invoiceDetails.forEach(ele => {
        body.push(this.getTableItem(ele));
      })
    }

    return body;
  }

  private getTableHeader() {
    return [{ text: 'ITEM ID', style: 'tableHeader' },
    { text: 'DESCRIPTION', style: 'tableHeader' },
    { text: 'PRICE', style: 'tableHeader' },
    { text: 'QTY.', style: 'tableHeader' },
    { text: 'AMOUNT', style: 'tableHeader' }];
  }

  private getTableItem(item: InvoiceTableBodyItemsModel) {
    return [
      { text: item.itemCode, style: 'tableColumn' },
      { text: item.description, style: 'tableColumn' },
      { text: item.price.toString(), style: 'tableColumn' },
      { text: item.qty.toString(), style: 'tableColumnCenter' },
      { text: item.amount.toString(), style: 'tableColumnCenter' }
    ];
  }

  private getTableTotal(total: InvoiceTotalModel) {
    return [
      {
        margin: [0, 10, 0, 0],
        columns: [
          {
            width: '*',
            text: ''
          },
          {
            width: 150,
            text: 'Subtotal',
            color: '#526484',
            bold: true,
            alignment: 'right',

          },
          {
            width: 'auto',
            text: `$${total.subtotal}`,
            color: '#526484',
            bold: true,
            margin: [20, 0, 10, 0]
          },

        ]
      },
      {
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: '*',
            text: ''
          },
          {
            width: 150,
            text: `Tax (${total.taxName}-${total.taxPercentage})%`,
            color: '#526484',
            bold: true,
            alignment: 'right'
          },
          {
            width: 'auto',
            text: `$${total.taxAmount}`,
            color: '#526484',
            bold: true,
            margin: [20, 0, 10, 0]
          },
        ],
      },
      {
        canvas: [
          {
            alignment: 'right',
            type: 'rect',
            x: 365,
            y: 10,
            w: 150,
            h: .2,
            r: 0,
            margin: [0, 10, 10, 0],
            lineWidth: 1,
            lineColor: '#DBDFEA',

          },

        ]
      },
      {
        margin: [0, 10, 0, 0],
        columns: [
          {
            width: '*',
            text: ''
          },
          {
            width: 150,
            text: 'GRAND TOTAL',
            color: '#526484',
            bold: true,
            alignment: 'right'
          },
          {
            width: 'auto',
            text: `$${total.subtotal + total.taxAmount}`,
            color: '#526484',
            bold: true,
            margin: [20, 0, 10, 0]
          },

        ]
      },
    ];

  }

  private getHeader() {
    return [
      {
        style: 'header',
        columns: [
          {
            width: '*',
            text: ''
          },
          {
            width: 30,
            svg: `<svg width="37" height="19" viewBox="0 0 37 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9875 0.423231C12.5086 0.658252 11.9586 1.13917 11.6635 1.58108L11.1589 2.33678L11.0644 7.6029C10.9598 13.424 10.946 13.4936 9.67612 14.5995C8.18612 15.8969 6.01769 15.8057 4.52618 14.3832C2.24767 12.2103 3.47572 8.39049 6.58869 7.96734C7.82695 7.79909 8.67276 8.01555 9.64151 8.74893L10.497 9.3966L10.4958 8.78813C10.4953 8.4537 10.4398 7.4823 10.3731 6.62943L10.2513 5.07919L9.2082 4.77852C5.87773 3.81837 2.07575 5.4819 0.55057 8.56662C0.0487965 9.58173 0 9.85501 0 11.6498C0 13.4141 0.053525 13.7268 0.513689 14.6509C1.20743 16.0441 1.96927 16.9173 3.19505 17.7242C6.88166 20.1509 11.9457 18.6945 13.8806 14.6509L14.3742 13.6193L14.4304 6.82C14.4938 -0.863475 14.6048 -0.370738 12.9875 0.423231ZM16.5765 4.61778C16.0193 4.7729 15.4873 4.94658 15.3944 5.0036C15.3014 5.06062 15.2253 6.08943 15.2253 7.28985V9.47256L15.84 8.89561C16.5765 8.20443 16.9922 7.98704 17.8817 7.82817C18.8255 7.65955 20.3772 8.15979 21.0838 8.86053C22.1764 9.94411 22.2855 10.347 22.4009 13.7279C22.5047 16.7715 22.5184 16.8524 23.037 17.5263C23.6097 18.2706 24.2351 18.6569 25.2022 18.8635L25.8169 18.9947V14.9411C25.8169 11.7067 25.8761 10.7471 26.1096 10.1928C27.4323 7.05333 31.956 7.05333 33.2786 10.1928C33.4963 10.7094 33.5714 11.5716 33.5714 13.554C33.5714 15.8879 33.6215 16.3231 33.9725 17.0424C34.4087 17.9359 35.3657 18.665 36.3732 18.8715L37 19L36.9406 14.3403C36.882 9.73253 36.8755 9.66838 36.3728 8.60451C35.0024 5.70473 31.6307 3.93335 28.5527 4.49567C27.022 4.77552 25.922 5.26807 24.91 6.12732C24.4914 6.48238 24.0906 6.77311 24.0189 6.77311C23.9475 6.77311 23.5054 6.4383 23.0368 6.02903C21.439 4.63447 18.6802 4.03182 16.5765 4.61778Z" fill="#0788FF"/>
          </svg>
          `
          },
          {
            width: 'auto',
            text: 'Dispatch Management',
            style: 'headerText'
          },
          {
            width: '*',
            text: ''
          },
        ],
      }
    ];
  }

  private getFooter() {
    return [
      {
        color: '#484C72',
        margin: [10, 0, 0, 0],
        text: 'Invoice was created on a computer and is valid without the signature and seal.'
      }
    ]
  }

  private getStyle() {
    return {
      header: {
        margin: [0, 20, 0, 0]
      },
      headerText: {
        fontSize: 16,
        bold: true,
        alignment: 'justify',
        margin: [5, 0, 0, 0]
      },
      invoiceInfo: {
        margin: [0, 20, 0, 20]
      },
      tableHeader: {
        color: '#0D6EFD',
        bold: true,
        margin: [0, 0, 0, 5]
      },
      tableColumnCenter: {
        alignment: 'center',
        margin: [0, 3, 0, 5]
      },
      tableColumn: {
        margin: [0, 3, 0, 0]
      }
    }
  }

  private getImages() {
    return {
      building: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAATCAYAAAAXvcSzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKHSURBVHgBtVbbcdpAFD13IWA7P6QDqABSQXAHUEFwBbF/4vAV+Eiwv4wrMKkguAIrFZhUEKUDZSYZEGR0c3cRsFJWBmzrzGhmd3UfZ+9LAvZEccDN8gXfFS65hZyg9hEufeZWgXAny2YRqCIn7EUKhI+rJUeoICfsRYooPyI2iumDSo8rv8tokJJHR4MxUYTJrEu+S/ZPGS15X2cY2Z8R4P3tkud0JvVo7DLqBAQR43tB5NO2yd6ULlmn51SU/osIE9pyfoW4lpjRl8i9kWXT4d8Xh/1Fl0bGrtSikFnrOmz35ufUT5A6GHBVbvpVdg1kQJycqGVNVa392wxSSx3C0NQH4xTbIBk5DHEc9CiIddyE5AbBaq0UKpxRU7Gclz5XmkyKUCzr27YNxP/0QHhovRcD7qQI+ZKm4/ADkYT0laxrkqovutscaQ3k7ETLibzRURHOkB2N66MpaiJX0zpis639WRJNU3elAd/ThpQh5Cpqk2LCj9Vep28RYgwJt0O2I7I3CT5SY/Mu9Vx2I4V768K3yiKk0+i5CGk4zx2EYtkRkhHAUYhhpt0It9ZRXaVu8w3Ph4m19oOMC6ze25sEKV3MWVovP3FmZ7ogF/y1q6yMiwRhleiCyLS4E3OV7M60oacgilKkpFuuN57QKF3wTVopnsRX1pEv3TNGPqgWZ1KApUO8W1W/TNOO/Jo0ZTnWKVhPbd5oiWx/B+M+Homi7iA14DYvf0nWbKE/N+TQkFkTd9eDMOll7ISCHqbW3hS6OPFkVrzGA7cztUc4C7u0/ZMhCKcYYcdozZYfcC9BSmPxniZ60uoJHQv48ePpdJlJfE5D7ArJgB7E5mtA25sinJnpruvb/wdEOhJQ0xgMAQAAAABJRU5ErkJggg=='
    }
  }
}
