import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    saleEndpoints!: SaleEndpointConfiguration;
}

export class SaleEndpointConfiguration {
    subscriptionEndpoints!: SubscriptionEndpoints;
    servicesEndpoints!: ServicesEndpoints;
    shareSubscriptionEndpoints!: ShareSubscriptionEndpoints;
}

export class SubscriptionEndpoints {
    create!: string;
    getAll!: string;
    changeStatus!: string;
}

export class ServicesEndpoints {
    de_activate!: string;
    activate!: string;
    update!: string;
    getDetails!: string;
    getAll!: string;
}

export class ShareSubscriptionEndpoints {
    users!: string;
    share!: string;
    delete!: string;
}

export const superSaleConfiguration: Configuration = {
    baseUrl: endpoints.identity,
    saleEndpoints: {
        subscriptionEndpoints: {
            create: apiRoutes.sale_management.subscription.create,
            getAll: apiRoutes.sale_management.subscription.getAllSubscription,
            changeStatus: apiRoutes.sale_management.subscription.changeStatus,
        },
        servicesEndpoints: {
            activate: apiRoutes.sale_management.service.activate,
            de_activate: apiRoutes.sale_management.service.de_activate,
            getAll: apiRoutes.sale_management.service.getAll,
            getDetails: apiRoutes.sale_management.service.getDetails,
            update: apiRoutes.sale_management.service.update,
        },
        shareSubscriptionEndpoints: {
            delete: apiRoutes.sale_management.share_subscriprion.delete,
            share: apiRoutes.sale_management.share_subscriprion.share,
            users: apiRoutes.sale_management.share_subscriprion.users
        }
    }
}