import { endpoints } from "src/environments/environment";

export const apiRoutes = {
  user_management: {
    customer: {
      create: `${endpoints.user_management}/${endpoints.version1}/customer/create`,
      delete: `${endpoints.user_management}/${endpoints.version1}/customer/delete/{id}`,
      update: `${endpoints.user_management}/${endpoints.version1}/customer/update/{id}`,
      getDetails: `${endpoints.user_management}/${endpoints.version1}/customer/getcustomerbyid/{id}`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/customer/getallcustomer`,
      changeStatus: `${endpoints.user_management}/${endpoints.version1}/customer/changestatus`,
      uploadImage: `${endpoints.user_management}/${endpoints.version1}/customer/uploadpicture`,
      uploadDocument: `${endpoints.user_management}/${endpoints.version1}/customer/uploaddocument`,
      fetchDocument: `${endpoints.user_management}/${endpoints.version1}/customer/fetchdocuments/{customerid}`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/customer/updatedocument/{documentid}`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/customer/deletedocument/{documentid}`,
      downloadcsv: `${endpoints.user_management}/${endpoints.version1}/customer/downloadCsv`,
      getterm: `${endpoints.user_management}/${endpoints.version1}/customer/getterm`,
      createterm: `${endpoints.user_management}/${endpoints.version1}/customer/createterm`,
    },
    foreigner: {
      create: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/create`,
      delete: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/delete/{id}`,
      update: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/update/{id}`,
      getDetails: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/getforeigncustomerbyid/{id}`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/getallforeigncustomer`,
      changeStatus: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/changestatus`,
      uploadImage: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/uploadpicture`,
      uploadDocument: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/uploaddocument`,
      fetchDocument: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/fetchdocuments/{foreigncustomerid}`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/updatedocument/{documentid}`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/deletedocument/{documentid}`,
      downloadcsv: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/downloadCsv`,
      getterm: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/getterm`,
      createterm: `${endpoints.user_management}/${endpoints.version1}/foreigncustomer/createterm`,
    },
    truck: {
      create: `${endpoints.user_management}/${endpoints.version1}/truck/create`,
      delete: `${endpoints.user_management}/${endpoints.version1}/truck/delete/{truckid}`,
      update: `${endpoints.user_management}/${endpoints.version1}/truck/update/{truckid}`,
      getDetails: `${endpoints.user_management}/${endpoints.version1}/truck/gettruckbyid/{truckid}`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/truck/getalltruck`,
      getDrivers: `${endpoints.user_management}/${endpoints.version1}/truck/getdrivers`,
      uploadImage: `${endpoints.user_management}/${endpoints.version1}/truck/uploadpicture`,
      uploadDocuments: `${endpoints.user_management}/${endpoints.version1}/truck/uploaddocument`,
      fetchDocuments: `${endpoints.user_management}/${endpoints.version1}/truck/fetchdocuments/{truckid}`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/truck/updatedocument/{documentid}`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/truck/deletedocument/{documentid}`,
      downloadCSV: `${endpoints.user_management}/${endpoints.version1}/truck/downloadCsv`,
      changeStatus: `${endpoints.user_management}/${endpoints.version1}/truck/changestatus`
    },
    trailer: {
      create: `${endpoints.user_management}/${endpoints.version1}/trailor/create`,
      delete: `${endpoints.user_management}/${endpoints.version1}/trailor/delete/{trailorid}`,
      update: `${endpoints.user_management}/${endpoints.version1}/trailor/update/{trailorid}`,
      getById: `${endpoints.user_management}/${endpoints.version1}/trailor/gettrailorbyid/{trailorid}`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/trailor/getalltrailor`,
      uploadImage: `${endpoints.user_management}/${endpoints.version1}/trailor/uploadpicture`,
      uploadDocuments: `${endpoints.user_management}/${endpoints.version1}/trailor/uploaddocument`,
      fetchDocuments: `${endpoints.user_management}/${endpoints.version1}/trailor/fetchdocuments/{trailorid}`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/trailor/updatedocument/{documentid}`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/trailor/deletedocument/{documentid}`,
      downloadCSV: `${endpoints.user_management}/${endpoints.version1}/trailor/downloadCsv`,
      changeStatus: `${endpoints.user_management}/${endpoints.version1}/trailor/changestatus`
    },
    trailerType: {
      create: `${endpoints.user_management}/${endpoints.version1}/TrailerType/create`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/TrailerType/getalltrailortype`
    },
    driver: {
      create: `${endpoints.user_management}/${endpoints.version1}/driver/create`,
      delete: `${endpoints.user_management}/${endpoints.version1}/driver/delete/{id}`,
      update: `${endpoints.user_management}/${endpoints.version1}/driver/update/{id}`,
      getDetails: `${endpoints.user_management}/${endpoints.version1}/driver/getdriverbyid/{id}`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/driver/getalldriver`,
      downloadCSV: `${endpoints.user_management}/${endpoints.version1}/driver/downloadCsv`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/driver/deletedocument/{documentid}`,
      fetchDocuments: `${endpoints.user_management}/${endpoints.version1}/driver/fetchdocuments/{driverid}`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/driver/updatedocument/{documentid}`,
      uploadDocuments: `${endpoints.user_management}/${endpoints.version1}/driver/uploaddocument`,
      uploadImage: `${endpoints.user_management}/${endpoints.version1}/driver/uploadpicture`,
      changeStatus: `${endpoints.user_management}/${endpoints.version1}/driver/changestatus`,
      getDriversByLoadId: `${endpoints.user_management}/${endpoints.version1}/driver/getdriversbyloadid/{loadid}`,
      driverBalanceDetail: `${endpoints.user_management}/${endpoints.version1}/driver/driverbalancedetail/{driverId}`,
      driverPayment: `${endpoints.user_management}/${endpoints.version1}/DriverPayment/payment`,
      driverResendActivationLink: `${endpoints.user_management}/${endpoints.version1}/driver/resendlink`,
    },
    shipper: {
      create: `${endpoints.user_management}/${endpoints.version1}/shipper/create`,
      delete: `${endpoints.user_management}/${endpoints.version1}/shipper/delete/{id}`,
      update: `${endpoints.user_management}/${endpoints.version1}/shipper/update/{id}`,
      getDetails: `${endpoints.user_management}/${endpoints.version1}/shipper/getshipperbyid/{id}`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/shipper/getallshipper`,
      downloadCSV: `${endpoints.user_management}/${endpoints.version1}/shipper/downloadCsv`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/shipper/deletedocument/{documentid}`,
      fetchDocuments: `${endpoints.user_management}/${endpoints.version1}/shipper/fetchdocuments/{shipperid}`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/shipper/updatedocument/{documentid}`,
      uploadDocuments: `${endpoints.user_management}/${endpoints.version1}/shipper/uploaddocument`,
      uploadImage: `${endpoints.user_management}/${endpoints.version1}/shipper/uploadpicture`,
      changeStatus: `${endpoints.user_management}/${endpoints.version1}/shipper/changestatus`,
    },
    dashboard: {
      headers: `${endpoints.user_management}/${endpoints.version1}/Dashboard/dashboardheaders`,
      activeLoad: `${endpoints.user_management}/${endpoints.version1}/Dashboard/getactiveload`,
      loadDetails: `${endpoints.user_management}/${endpoints.version1}/Dashboard/getloaddetails`,
      burndownchart: `${endpoints.user_management}/${endpoints.version1}/Dashboard/getburndown`
    },
    region: {
      country: `${endpoints.user_management}/${endpoints.version1}/Region/countries`,
      state: `${endpoints.user_management}/${endpoints.version1}/Region/states/{country}`,
      city: `${endpoints.user_management}/${endpoints.version1}/Region/cities/{state}`
    },
    load: {
      create: `${endpoints.user_management}/${endpoints.version1}/Load/create`,
      update: `${endpoints.user_management}/${endpoints.version1}/Load/update/{loadid}`,
      assign: `${endpoints.user_management}/${endpoints.version1}/Load/assign`,
      status: `${endpoints.user_management}/${endpoints.version1}/Load/status`,
      getloads: `${endpoints.user_management}/${endpoints.version1}/Load/getloads`,
      getloadbyid: `${endpoints.user_management}/${endpoints.version1}/Load/getloadbyid/{loadid}`,
      getnextstatus: `${endpoints.user_management}/${endpoints.version1}/Load/getnextstatus/{currentstatus}`,
      getdrivers: `${endpoints.user_management}/${endpoints.version1}/Load/getdrivers?trucktype={truckType}&truckId={truckId}`,
      gettrucks: `${endpoints.user_management}/${endpoints.version1}/Load/gettruck?trucktype={truckType}`,
      gettrailers: `${endpoints.user_management}/${endpoints.version1}/Load/gettrailer`,
      gettrailertype: `${endpoints.user_management}/${endpoints.version1}/Load/gettrailertype`,
      getloadstatus: `${endpoints.user_management}/${endpoints.version1}/Load/getloadstatus/{loadid}`,
      getworkorderform: `${endpoints.user_management}/${endpoints.version1}/Load/getworkorderform/{loadid}`,
      createworkorder: `${endpoints.user_management}/${endpoints.version1}/Load/createworkorder`,
      fetchdocuments:`${endpoints.user_management}/${endpoints.version1}/Load/fetchdocuments/{loadid}`,
      uploadDocuments: `${endpoints.user_management}/${endpoints.version1}/Load/uploaddocument`,
      updateDocument: `${endpoints.user_management}/${endpoints.version1}/Load/updatedocument/{documentid}`,
      deleteDocument: `${endpoints.user_management}/${endpoints.version1}/Load/deletedocument/{documentid}`,
      getworkorder: `${endpoints.user_management}/${endpoints.version1}/Load/getworkorder/{loadid}`,
      uploadworkdocument: `${endpoints.user_management}/${endpoints.version1}/Load/uploadworkdocument`,
      completeloadstatus: `${endpoints.user_management}/${endpoints.version1}/Load/completeloadstatus`,
      getloadforupdate: `${endpoints.user_management}/${endpoints.version1}/Load/getloadforupdate/{loadid}`,
      getcompletedloadforupdate: `${endpoints.user_management}/${endpoints.version1}/Load/getcompletedloadforupdate/{loadid}`,
      getreinvoices: `${endpoints.user_management}/${endpoints.version1}/Load/getreinvoices/{loadmasterid}`,
      getinvoice: `${endpoints.user_management}/${endpoints.version1}/Load/getinvoicedetail?loadMasterId={loadMasterId}&saleInvoiceId={saleInvoiceId}`,
      loadbalancedetail: `${endpoints.user_management}/${endpoints.version1}/Load/loadbalancedetail/{saleInvoiceId}`,
      loadpayment: `${endpoints.user_management}/${endpoints.version1}/CustomerPayment/payment`,
    },
    loadType: {
      create: `${endpoints.user_management}/${endpoints.version1}/LoadType/create`,
      getAll: `${endpoints.user_management}/${endpoints.version1}/LoadType/getallloadtype`

    },
    printerSettings: {
      updateprint: `${endpoints.user_management}/${endpoints.version1}/PrintSettings/updateprint`,
      getsettings: `${endpoints.user_management}/${endpoints.version1}/PrintSettings/getsettings`,
      uploadlogo: `${endpoints.user_management}/${endpoints.version1}/PrintSettings/uploadlogo`,
    },
    masterprintercodeSettings: {
      updateprint: `${endpoints.user_management}/${endpoints.version1}/MasterManagePrinterSetting/updateprint/{companyId}`,
      getsettings: `${endpoints.user_management}/${endpoints.version1}/MasterManagePrinterSetting/getsettings/{companyId}`,
      uploadlogo: `${endpoints.user_management}/${endpoints.version1}/MasterManagePrinterSetting/uploadlogo/{companyId}`,
      getcodesettings: `${endpoints.user_management}/${endpoints.version1}/MasterManagePrinterSetting/getcodesettings/{companyId}`,
      updatecodesettings: `${endpoints.user_management}/${endpoints.version1}/MasterManagePrinterSetting/updatecodesettings/{companyId}`,
    },
    settings: {
      getcodesettings: `${endpoints.user_management}/${endpoints.version1}/Settings/getcodesettings`,
      updatecodesettings: `${endpoints.user_management}/${endpoints.version1}/Settings/updatecodesettings`,
    },
    reports: {
      customerledger: `${endpoints.user_management}/${endpoints.version1}/Reports/customerledger/{customerid}`,
      driverledger: `${endpoints.user_management}/${endpoints.version1}/Reports/driverledger/{driverid}`,
      driversrevenue: `${endpoints.user_management}/${endpoints.version1}/Reports/driversrevenue`,
      driversinspection: `${endpoints.user_management}/${endpoints.version1}/Reports/driversinspection/{driverid}`,
      driversreport: `${endpoints.user_management}/${endpoints.version1}/Reports/driversreport`,
      getUserFailedPayments: `${endpoints.user_management}/${endpoints.version1}/Reports/getUserFailedPayments`
    },
    expenses: {
      create: `${endpoints.user_management}/${endpoints.version1}/expenses/create`,
      getAllExpenses: `${endpoints.user_management}/${endpoints.version1}/expenses/getallexpenses`,
      uploadPictures: `${endpoints.user_management}/${endpoints.version1}/expenses/uploadpicture`
    },
    notifications: {
      getnotification: `${endpoints.user_management}/${endpoints.version1}/Notification/notification`
    },
    verification: {
      verifyUserAccount: `${endpoints.user_management}/${endpoints.version1}/Verification/verify?code={code}`,
    }
  },
  authorization: {
    login: `${endpoints.identity}/${endpoints.version1}/auth/login`,
    regenerateToken: `${endpoints.identity}/${endpoints.version1}/auth/retoken`,
    register: `${endpoints.identity}/${endpoints.version1}/auth/register`,
    verification: `${endpoints.identity}/${endpoints.version1}/auth/verification`,
    forgetPassword: `${endpoints.identity}/${endpoints.version1}/auth/forgetpassword`,
    verifyResetLink: `${endpoints.identity}/${endpoints.version1}/auth/verifyresetlink`,
    resetpassword: `${endpoints.identity}/${endpoints.version1}/auth/resetpassword`,
    communicationconnection: `${endpoints.identity}/${endpoints.version1}/auth/communicationconnection`
  },
  userManagemntEndpointConfiguration: {
    registeruser: `${endpoints.identity}/${endpoints.version1}/UserManagement/registeruser`,
    resendactivation: `${endpoints.identity}/${endpoints.version1}/UserManagement/resendlink`,
  },
  company: {
    create: `${endpoints.identity}/${endpoints.version1}/Company/create`,
  },
  super_admin: {
    customer: {
      create: `${endpoints.identity}/${endpoints.version1}/User/createcustomer`,
      activate: `${endpoints.identity}/${endpoints.version1}/User/activate`,
      de_activate: `${endpoints.identity}/${endpoints.version1}/User/deactivate`,
      update: `${endpoints.identity}/${endpoints.version1}/User/create`,
      getDetails: `${endpoints.identity}/${endpoints.version1}/User/create`,
      getAll: `${endpoints.identity}/${endpoints.version1}/User/getusers`,
      uploadImage: `${endpoints.identity}/${endpoints.version1}/User/uploadphoto`
    }
  },
  request_demo: {
    demo: `${endpoints.identity}/${endpoints.version1}/RequestDemo/demo`
  },
  sale_management: {
    opensubscription: {
      getsubscriptions: `${endpoints.sale_management}/${endpoints.version1}/Anonymous/getsubscription`
    },
    service: {
      de_activate: `${endpoints.sale_management}/${endpoints.version1}/Service/de_activate/{id}`,
      activate: `${endpoints.sale_management}/${endpoints.version1}/Service/activate/{id}`,
      update: `${endpoints.sale_management}/${endpoints.version1}/Service/update/{id}`,
      getDetails: `${endpoints.sale_management}/${endpoints.version1}/Service/getservice/{id}`,
      getAll: `${endpoints.sale_management}/${endpoints.version1}/Service/getservices`,
    },
    subscription: {
      create: `${endpoints.sale_management}/${endpoints.version1}/Subscription/create`,
      getSubscriptionById: `${endpoints.sale_management}/${endpoints.version1}/Subscription/getbyid/{subscriptionId}`,
      fakePayment: `${endpoints.sale_management}/${endpoints.version1}/Temp/payment_success`,
      getPaymentLink: `${endpoints.sale_management}/${endpoints.version1}/PlanSubscription/order`,
      checkPayment: `${endpoints.sale_management}/${endpoints.version1}/Subscription/checkpayment`,
      subscriptionPayment: `${endpoints.sale_management}/${endpoints.version1}/PaypalPaymentWebhook/PaypalPaymentSubscription`,
      getAllSubscription: `${endpoints.sale_management}/${endpoints.version1}/Subscription/getsubscriptions`,
      changeStatus: `${endpoints.sale_management}/${endpoints.version1}/Subscription/changestatus`,
      currentsubscription: `${endpoints.sale_management}/${endpoints.version1}/UserSubscription/usersubscription`,
      getinvoices: `${endpoints.sale_management}/${endpoints.version1}/UserSubscription/getinvoices`,
      mastercurrentsubscription: `${endpoints.sale_management}/${endpoints.version1}/MasterManageSubscription/currentsubscription/{companyId}`,
      mastergetinvoices: `${endpoints.sale_management}/${endpoints.version1}/MasterManageSubscription/getsales/{companyId}`,
    },
    reports: {
      orders: `${endpoints.sale_management}/${endpoints.version1}/MasterReport/getorders`,
      payments: `${endpoints.sale_management}/${endpoints.version1}/MasterReport/getpayments`,

    },
    functions: {
      failedPayment: `${endpoints.sale_management}/${endpoints.version1}/MasterReport/failedpayments`,
      retryPayment: `${endpoints.sale_management}/${endpoints.version1}/Subscription/retrypayment`,
    },
    share_subscriprion: {
      users: `${endpoints.sale_management}/${endpoints.version1}/SubscriptionShare/users/{subscriptionId}`,
      share: `${endpoints.sale_management}/${endpoints.version1}/SubscriptionShare/sharesubscription`,
      delete: `${endpoints.sale_management}/${endpoints.version1}/SubscriptionShare/delete/{emailid}`
    }
  },
  identityUser: {
    userprofile: {
      changePassowrd: `${endpoints.identity}/${endpoints.version1}/UserProfile/changepassword`,
      changeProfile: `${endpoints.identity}/${endpoints.version1}/UserProfile/uploadphoto`,
      changeCompanyLogo: `${endpoints.identity}/${endpoints.version1}/UserProfile/companylogo`,
      profile: `${endpoints.identity}/${endpoints.version1}/UserProfile/userprofile`,
      updateProfile: `${endpoints.identity}/${endpoints.version1}/UserProfile/updateprofile`,
      company: `${endpoints.identity}/${endpoints.version1}/UserProfile/companyprofile`,
      updateCompany: `${endpoints.identity}/${endpoints.version1}/UserProfile/updatecompany`,
      notification: `${endpoints.identity}/${endpoints.version1}/UserProfile/notifications`,
      updateNotification: `${endpoints.identity}/${endpoints.version1}/UserProfile/updatenotification`,
      uploadDocuments: `${endpoints.identity}/${endpoints.version1}/Company/uploaddocument`,
      fetchDocuments: `${endpoints.identity}/${endpoints.version1}/Company/getdocuments`,
      updateDocument: `${endpoints.identity}/${endpoints.version1}/Company/updatedocument/{documentid}`,
      deleteDocument: `${endpoints.identity}/${endpoints.version1}/Company/deletedocument/{documentid}`
    },
    mastermanageprofile: {
      changePassowrd: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/changepassword/{customerid}`,
      changeProfile: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/uploadphoto/{customerid}`,
      changeCompanyLogo: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/companylogo/{companyid}`,
      profile: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/userprofile/{customerid}`,
      updateProfile: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/updateprofile/{customerid}`,
      company: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/companyprofile/{companyid}`,
      updateCompany: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/updatecompany/{companyid}`,
      notification: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/notifications/{customerid}`,
      updateNotification: `${endpoints.identity}/${endpoints.version1}/MasterManageProfile/updatenotification/{customerid}`,
    },
    paymentBillingAddress: {
      createAddress: `${endpoints.identity}/${endpoints.version1}/PaymentAddress/createbillingaddress`,
      getAddress: `${endpoints.identity}/${endpoints.version1}/PaymentAddress/getbillingaddress`,
      updateAddress: `${endpoints.identity}/${endpoints.version1}/PaymentAddress/updatebillingaddress/{id}`,
    }
  },
  communication:{
    connect:{
      endpoint: `${endpoints.base_address}/loadstatuscommunication`,
      join: `${endpoints.communication}/${endpoints.version1}/Communication/connect`,
      remove: `${endpoints.communication}/${endpoints.version1}/Communication/disconnect`,
    }
  }
};
