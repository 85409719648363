import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResultModel } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { driverConfiguration } from "src/app/components/modules/configuration/driver.config.model";
import { identityConfiguration } from "src/app/components/modules/configuration/identity.config.model";
import { DriverBalanceDetailsModel } from "src/models/driver-balance-details.model";
import { DriverPaymentRequestModel } from "src/models/driver-payment.request.model";
import { DriverResponseModel } from "src/models/drivers-response.model";

@Injectable()
export class UserManagementService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };
    constructor(private client: HttpClient) {

    }

    createUserAccount(name: string, email: string): Observable<ResultModel<string>> {
        return this.client.post<ResultModel<string>>(identityConfiguration.userManagemntEndpointConfiguration.registeruser, { name, email });
    }

    resendActicationLink(email: string): Observable<ResultModel<string>>{
        return this.client.post<ResultModel<string>>(identityConfiguration.userManagemntEndpointConfiguration.resendactivation, {email});
    }

    getDrivers(): Observable<ResultModel<DriverResponseModel[]>> {
        return this.client.get<ResultModel<DriverResponseModel[]>>(driverConfiguration.userEndpoints.driverEndpoints.getAllDriver);
    }

    getDriversBalanceDetails(driverId: string): Observable<ResultModel<DriverBalanceDetailsModel>> {
        const route = driverConfiguration.userEndpoints.driverEndpoints.driverBalanceDetail.replace('{driverId}', driverId);
        return this.client.get<ResultModel<DriverBalanceDetailsModel>>(route);
    }

    driverPayment(payDetails: DriverPaymentRequestModel): Observable<ResultModel<string>> {
        return this.client.post<ResultModel<string>>(driverConfiguration.userEndpoints.driverEndpoints.driverPayment, payDetails);
    }

}