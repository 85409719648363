import { Component } from '@angular/core';
import { AdminPrintReportService } from '@dmspro/print-documents';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.scss']
})
export class PrintReportComponent {
  constructor(private adminPrintReportService: AdminPrintReportService) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  async printDocument() {
    const testDocument = await this.adminPrintReportService.getCustomerLedger();
    pdfMake.createPdf(testDocument).open();
  }
}
