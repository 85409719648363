import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { AutoCoverageItems, AutoCoverageScheduleModel, BillingDetailModel, DriverDetailModel, FullPaidPolocyDetail, MotorTruckCargoCoverageItem, PaymentPlanModel, PolicyIssueDetailModel, SuperQuotationsService } from '@dmspro/print-documents';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-print-quotation',
  templateUrl: './print-quotation.component.html',
  styleUrls: ['./print-quotation.component.scss']
})
export class PrintQuotationComponent {

  constructor(private superQuotationsService: SuperQuotationsService, public datepipe: DatePipe) {
    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
  }


  async printDocument() {
    const companyDetail: BillingDetailModel = {
      name: 'ABC COMPANY',
      address: 'Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem',
      mobileNo: ''
    };
    
    const customerDetail: BillingDetailModel = {
      name: 'Abhishek Raheja',
      address: 'Hno 15, R.D. Colony, Street Number 8, Old Suraj Nagari, Abohar, Fazilka, Punjab 152116',
      mobileNo: '9914964911'
    };

    const policyIssued: PolicyIssueDetailModel = {
      date: this.datepipe.transform(new Date(), 'MMM,dd-yyyy'),
      policyStart: 'Jan, 23-2023',
      policyEnd: 'Jan, 23-2024',
      preparedBy: 'ABC COMPANY',
      policyFor: 'Trucker' // DROP DOWN --FIXED VALUE
    };
    
    const driver: DriverDetailModel = {
      driverAdditional: 'Additional Information that will be awesome thing added in the sheet',
      driverDOB: 'Jul, 23-1991',
      driverName: 'Abhishek Raheja',
      driverPoint: 5
    }
    const outstandingPolicy: FullPaidPolocyDetail = {
      policyPremium: 2000,
      discount: 100,
      total: 1900 // FORMULLA (policyPremium-discount)
    };

    const paymentPlanModel: PaymentPlanModel[] = [
      {
        paymentPlan: '11 Payments, 9.09% Down',
        totalPremium: 20666,
        initialPremium: 1910,
        paymentsDetail: '9 Payments of $1,878.57 and 1 of $1,878.51'
      },
      {
        paymentPlan: '11 Payments, 9.09% Down',
        totalPremium: 20666,
        initialPremium: 1910,
        paymentsDetail: '9 Payments of $1,878.57 and 1 of $1,878.51'
      }
    ];

    const autoCoverageItems: AutoCoverageItems[] = [
      {
        description: 'Item 1',
        limit: '10000 test value',
        deductible: '',
        premium: 1000
      },
      {
        description: 'Item 2',
        limit: '10000 test value',
        deductible: '',
        premium: 0
      }
    ];

    const motorTruckCargoCoverageItem: MotorTruckCargoCoverageItem = {
      federalFillingFee: 200,
      subTotalPolicyPremium: 1400,
      total: 1600,
      coverage: [
        {
          description: 'Test Description 1',
          deductible: '1600',
          limit: 'Hello limit 1',
          premium: 1400
        },
        {
          description: 'Test Description 2',
          deductible: '0',
          limit: 'Hello limit 1',
          premium: 0
        }
      ]
    };
    const ratedCommodities: string[] = [
      'Other Food and beverages',
      'Meats',
      'Vegetables'
    ];

    const autoCoverageScheduleModel: AutoCoverageScheduleModel = {
      truckerName: '2018 FRIGHTLINER CASECADEIA 126',
      amount: 135000,
      vinNumber: '3AKJABSDHBHHD',
      radius: "552",
      garagingZipCode: '46413',
      nBodyType: 'Truck Tractor',
      liabilityPremuium: [1200],
      physicalPremuium: [122, 1522, 515, 515],
      otherCoveragePremuium: [155, 515]
    };


    const testDocument = await this.superQuotationsService.getQuotationsDocuments(
      companyDetail, customerDetail, policyIssued, outstandingPolicy, paymentPlanModel,
      driver, autoCoverageItems, motorTruckCargoCoverageItem, ratedCommodities, autoCoverageScheduleModel);
    pdfMake.createPdf(testDocument).open();
  }
}
