import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@dmspro/notification';
import { ControlValidation, CustomControlForm } from '@dmspro/shared-lib';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {

  isFormLoading: boolean = false;
  forgetForm: FormGroup;

  constructor(private loginService: LoginService, private toastService: ToastService) {
    this.forgetForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email])
    })
  }

  ngOnInit(): void {
  }

  forgetPassword() {
    this.isFormLoading = true;
    this.loginService.forgetPassword(this.forgetForm.controls['username'].value).subscribe({
      next: (res) => {
        if (res.success) {
          this.toastService.notification.success(res.data);
          this.forgetForm.reset();
          this.isFormLoading = false;
          CustomControlForm.resetForm(this.forgetForm);
        }
      },
      error: (err) => {
        this.toastService.notification.error(err.message);
        this.isFormLoading = false;
      }
    });
  }


  controlErrorMessage(controlName: string): string {
    return ControlValidation.ControlError(this.forgetForm.controls[controlName] as any);
  }

}
