<div class="modal" style="z-index: 150;" #driverPaymentModal>
    <div class="modal-background"></div>
    <div class="modal-card">

        <section class="modal-card-body box">
            <div>
                <div class="upload-image-size has-background-white-ter is-relative">
                    <mat-icon class="div-center">
                        attach_money
                    </mat-icon>
                </div>
                <div class="mt-3 ml-3">
                    <span class="is-size-5 is-block">Payment to Drivers</span>
                    <span class="has-text-grey-light is-block mt-1 is-size-7">Select Driver and add payment to drivers
                        accounts.</span>
                </div>
                <div class="mt-5">
                    <form [formGroup]="driverPaymentForm" (ngSubmit)="payNow()">

                        <div>
                            <div class="is-flex">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Select Driver</mat-label>
                                    <mat-select formControlName="driverId"
                                        (selectionChange)="driverSelectionChange($event)">
                                        <mat-option>Select Driver</mat-option>
                                        <mat-option [value]="item.id" *ngFor="let item of drivers">
                                            <div class="is-flex is-flex-direction-row">
                                                <div>
                                                    <img [src]="item.driverImage" alt="img" />
                                                </div>
                                                <div>
                                                    {{item.driverName}}
                                                </div>
                                            </div>

                                        </mat-option>
                                    </mat-select>
                                    <mat-error>{{controlErrorMessage('driverId')}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="is-flex is-flex-direction-column mt-1 mb-5"
                                *ngIf="isDriverPaymentBasicVisible()" style="background-color: #F0F3FB;">
                                <div class="is-flex-grow-1 w-100 pt-3 pb-1 pl-3 pr-4">
                                    <div class="columns is-gapless mb-2">
                                        <div class="column is-6">
                                            <span>Current Outstanding</span>
                                        </div>
                                        <div class="column is-6 has-text-right">
                                            <span
                                                class="has-text-weight-semibold">${{selecteddriverBalanceDetails.income
                                                - selecteddriverBalanceDetails.received}}</span>
                                        </div>
                                    </div>

                                    <div class="columns is-gapless mb-2">
                                        <div class="column is-6">
                                            <span>Last Payment</span>
                                        </div>
                                        <div class="column is-6 has-text-right">
                                            <span
                                                class="has-text-weight-semibold">${{selecteddriverBalanceDetails.lastPayment}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="is-flex is-flex-direction-row">
                                <mat-form-field class="w-100 mr-1" appearance="outline">
                                    <mat-label>Select Payment Method</mat-label>
                                    <mat-select formControlName="paymentMethod">
                                        <mat-option>Select Payment Method</mat-option>
                                        <mat-option value="CASH">Cash</mat-option>
                                        <mat-option value="BANK">By Bank</mat-option>
                                        <mat-option value="CHEQUE">Cheque</mat-option>
                                        <mat-option value="OTHERS">Others</mat-option>
                                    </mat-select>
                                    <mat-error>{{controlErrorMessage('paymentMethod')}}</mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100 ml-1" appearance="outline">
                                    <mat-label>Amount</mat-label>
                                    <input type="text" matInput formControlName="amount" placeholder="Amount">
                                    <mat-error>
                                        {{controlErrorMessage('amount')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="is-flex">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Notes</mat-label>
                                    <input type="text" matInput formControlName="notes" placeholder="Notes">
                                    <mat-error>
                                        {{controlErrorMessage('notes')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="mt-4">
                            <button type="submit" [disabled]="(driverPaymentForm.invalid|| isFormLoading)"
                                mat-raised-button color="primary">Pay Now
                                <mat-icon *ngIf="isFormLoading">
                                    <mat-spinner color="accent" diameter="18">
                                    </mat-spinner>
                                </mat-icon>
                            </button>
                            <button type="button" mat-raised-button class="ml-2" color="basic"
                                (click)="closeModal()">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    </div>
</div>