import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@dmspro/notification';
import { firstValueFrom, map } from 'rxjs';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {

  constructor(private route: ActivatedRoute, 
    private loginService: LoginService, 
    private toastService: ToastService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    const params$ = this.route.queryParams.pipe(map((params: any) => params));
    const params = await firstValueFrom(params$);
    const { id, emailId, code } = params;
    const encodedCode= encodeURI(code);
    this.loginService.verification(id, emailId, encodedCode).subscribe({
      next: (res) => {
        if (res.success) {
          this.router.navigate(["/login"]);
          this.toastService.notification.success("Account verification Completed");
        }
      },
      error: (err) => {
        this.router.navigate(["/login"]);
        this.toastService.notification.error(err.message);
      }
    });
  }
}
