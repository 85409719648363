import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    saleEndpoints!: SaleEndpointConfiguration;
}

export class SaleEndpointConfiguration {
    subscriptionEndpoints!: SubscriptionEndpoints;
   
}

export class SubscriptionEndpoints {
    getSubscription!: string;
}

export const saleConfiguration: Configuration = {
    baseUrl: endpoints.sale_management,
    saleEndpoints: {
        subscriptionEndpoints: {
            getSubscription: apiRoutes.sale_management.opensubscription.getsubscriptions
        },
    }
}