<div class="is-flex is-flex-direction-row h-100">
    <div>
        <app-side-nav *ngIf="role===roleConstants.admin || role===roleConstants.emp" #sideNav></app-side-nav>
        <app-super-side-nav *ngIf="role===roleConstants.superAdmin" #sideNav></app-super-side-nav>

    </div>
    <div class="is-flex-grow-1">
        <div class="is-flex is-flex-direction-column h-100">
            <div>
                <div *ngIf=" role==roleConstants.admin || role==roleConstants.new || role===roleConstants.emp">
                    <app-nav-header (toggledEvent)="toggledEvent()" #headerNav></app-nav-header>
                </div>
                <div *ngIf="role==roleConstants.superAdmin">
                    <app-super-nav-header (toggledEvent)="toggledEvent()" #headerNav></app-super-nav-header>
                </div>
            </div>
            <div class="is-flex-grow-1 h-0 m-0" style="padding: 6px;">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>