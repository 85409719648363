import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResultModel } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { requestDemoConfiguration } from "src/app/components/modules/configuration/home.config.model";
import { DemoRequestModel } from "src/models/demo-request.model";

@Injectable()
export class RequestDemoService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private client: HttpClient) {

    }

    createUserAccount(request: DemoRequestModel): Observable<ResultModel<string>> {
        return this.client.post<ResultModel<string>>(requestDemoConfiguration.requestDemoConfiguration.demoLink, { ...request });
    }

}