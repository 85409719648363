import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '@dmspro/notification';
import { ControlValidation } from '@dmspro/shared-lib';
import { tap } from 'rxjs';
import { CompareValidators } from 'src/app/helpers/validators/compare.validator';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  isFormLoading = false;
  registerForm: FormGroup;
  showPassword = false;
  showConfPassword = false;
  constructor(private loginService: LoginService, private toastService: ToastService, private router: Router) {
    this.registerForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confirm: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    }, [CompareValidators.MatchValidator('password', 'confirm')]);
  }

  ngOnInit(): void {
  }

  controlErrorMessage(controlName: string): string {
    return ControlValidation.ControlError(this.registerForm.controls[controlName]);
  }

  passwordValidation(): string {
    const password = this.registerForm.controls['password'].value;
    const conf = this.registerForm.controls['confirm'].value;
    if (password !== conf) {
      return 'Password not matched';
    }
    return '';
  }

  onSubmit() {
    const controls = this.registerForm.controls;

    this.isFormLoading = true;
    this.loginService.register(controls['name'].value, controls['username'].value, controls['password'].value).subscribe({
      next: (res) => {
        if (res.success) {
          this.toastService.alert.success("Success", `Hi ${controls['name'].value},\nYour account is created successfully. \n\nPlease check your email id and verify your account.`);
          this.registerForm.reset();
          this.isFormLoading = false;
          this.router.navigate(['/login']);

        }
      },
      error: (err) => {
        this.isFormLoading = false;
        this.toastService.notification.error(err.message);
      }
    });
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  public toggleConfPasswordVisibility(): void {
    this.showConfPassword = !this.showConfPassword;
  }

}
