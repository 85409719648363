import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";


export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    foreignerEndpoints!: ForeignerEndpoints;
    regionEndpoints!: RegionEndpoints;
}

export class ForeignerEndpoints {
    create!: string;
    update!: string;
    getAllForeigner!: string;
    getForeignerById!: string;
    deleteForeigner!: string;
    changeStatus!: string;
    uploadImage!: string;
    uploadDocuments!: string;
    fetchDocuments!: string;
    updateDocument!: string;
    deleteDocument!: string;
    downloadCSV!: string;
    getterm!: string;
    createterm!: string;
}

export class RegionEndpoints {
    country!: string;
    state!: string;
    city!: string;
}

export const foreignerConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        foreignerEndpoints: {
            create: apiRoutes.user_management.foreigner.create,
            update: apiRoutes.user_management.foreigner.update,
            getAllForeigner: apiRoutes.user_management.foreigner.getAll,
            getForeignerById: apiRoutes.user_management.foreigner.getDetails,
            deleteForeigner: apiRoutes.user_management.foreigner.delete,
            changeStatus: apiRoutes.user_management.foreigner.changeStatus,
            uploadImage: apiRoutes.user_management.foreigner.uploadImage,
            uploadDocuments: apiRoutes.user_management.foreigner.uploadDocument,
            fetchDocuments: apiRoutes.user_management.foreigner.fetchDocument,
            updateDocument: apiRoutes.user_management.foreigner.updateDocument,
            deleteDocument: apiRoutes.user_management.foreigner.deleteDocument,
            downloadCSV: apiRoutes.user_management.foreigner.downloadcsv,
            getterm: apiRoutes.user_management.foreigner.getterm,
            createterm: apiRoutes.user_management.foreigner.createterm
        },
        regionEndpoints: {
            country: apiRoutes.user_management.region.country,
            state: apiRoutes.user_management.region.state,
            city: apiRoutes.user_management.region.city
        }
    }
}