import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppFeaturesConstant, AppRoleConstant, DecodeToken } from '@dmspro/shared-lib';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/models/user.model';
import { AuthenticationService } from 'src/services/authentication.service';
import { DriverPaymentComponent } from './driver-payment/driver-payment.component';
import { NewUserComponent } from './new-user/new-user.component';
import { NotificationService } from 'src/services/notificaiton.service';
import { NotificationResponseModel } from 'src/models/notification.response.model';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit {
  appFeatures = AppFeaturesConstant;

  public isOpen: boolean;
  public sideNav$: BehaviorSubject<boolean>;
  public userInformation: User;
  public isSubscribed: boolean = false;
  public batchCount: number = 0;

  notifications: NotificationResponseModel[] = [];

  @ViewChild('newuser')
  public addUserComponent: NewUserComponent;

  @ViewChild('driverPayment')
  public driverPayment: DriverPaymentComponent;

  role: string;
  roleConstants = {
    admin: AppRoleConstant.admin,
    superAdmin: AppRoleConstant.super_admin,
    new: AppRoleConstant.new,
    emp: AppRoleConstant.employee
  };

  constructor(private authService: AuthenticationService, private router: Router, private notificationService: NotificationService) {
    this.isOpen = true;
    this.sideNav$ = new BehaviorSubject(this.isOpen);
  }

  sideNavClick() {
    this.sideNav$.next(this.isOpen);
  }

  ngOnInit(): void {
    this.authService.currentUserSubject.subscribe(x => {
      this.userInformation = { ...x };
      this.role = x.role;
    });
    const tokenDetails = DecodeToken.getToken();
    this.isSubscribed = tokenDetails.has_subscribed;
    if (tokenDetails.has_subscribed) {
      if (tokenDetails.features.find(x => x === this.appFeatures.PRINTER_MANAGEMENT))
        this.authService.loadPrinterSettings();
    }

    if (this.isSubscribed && tokenDetails.role === this.roleConstants.admin || tokenDetails.role === this.roleConstants.emp) {
      this.fetchNotifications();
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/home']);
  }

  openNewUserModal() {
    this.addUserComponent.openModal();
  }

  openDriverPaymentModal() {
    console.log('test');
    this.driverPayment.openModal();
  }

  fetchNotifications() {
    this.notificationService.getNotification().subscribe({
      next: (res) => {
        if (res.success) {
          this.notifications = res.data.map(x => <NotificationResponseModel>{
            date: new Date(x.date),
            id: x.id,
            image: x.image,
            isActive: x.isActive,
            message: x.message,
            notificationType: x.notificationType,
            notificationTypeId: x.notificationTypeId
          });
          this.batchCount = this.notifications.filter(x => x.isActive).length;
        }
      },
      error: (err) => {

      }
    })
  }

}
