import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AppRoleConstant } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/services/authentication.service";

@Injectable({ providedIn: 'root' })
export class SuperAdminGuard implements CanActivateChild {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authenticationService.currentUserValue?.id) {
            if (this.authenticationService.currentUserValue.role = AppRoleConstant.super_admin)
                return true;
        }
        this.router.navigate(['/home'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}