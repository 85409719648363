import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    saleEndpoints!: SaleEndpointConfiguration;
}

export class SaleEndpointConfiguration {
    baseUrl!: string;
    subscriptionEndpoints!: SubscriptionEndpoints;
    identityEndpoints!: IdentityEndpointConfiguration;
    userManagementEndpoints: UserManagementEndpointsConfiguration;
}

export class IdentityEndpointConfiguration {
    userProfileEndpoints!: UserProfileEndpoints;
}


export class SubscriptionEndpoints {
    currentsubscription!: string;
    getinvoices!: string;
}

export class UserProfileEndpoints {
    profile!: string;
    updateProfile!: string;
    company!: string;
    updateCompany!: string;
    notification!: string;
    updateNotification!: string;
    changePassowrd!: string;
    changeProfile!: string;
    changeCompanyLogo!: string;
    uploadDocuments!: string;
    fetchDocuments!: string;
    updateDocument!: string;
    deleteDocument!: string;
}


export class UserManagementEndpointsConfiguration {
    printerSettingsEndpoints!: PrinterSettingsEndpoints;
    codeSettingsEndpoints!: CodeSettingsEndpoints;
}

export class PrinterSettingsEndpoints {
    updateprint!: string;
    getsettings!: string;
    uploadlogo!: string;
}
export class CodeSettingsEndpoints {
    getcodesettings!: string;
    updatecodesettings!: string;
}


export const subscriptionConfiguration: Configuration = {
    saleEndpoints: {
        baseUrl: endpoints.sale_management,
        subscriptionEndpoints: {
            currentsubscription: apiRoutes.sale_management.subscription.currentsubscription,
            getinvoices: apiRoutes.sale_management.subscription.getinvoices
        },
        identityEndpoints: {
            userProfileEndpoints: {
                profile: apiRoutes.identityUser.userprofile.profile,
                updateProfile: apiRoutes.identityUser.userprofile.updateProfile,
                company: apiRoutes.identityUser.userprofile.company,
                updateCompany: apiRoutes.identityUser.userprofile.updateCompany,
                notification: apiRoutes.identityUser.userprofile.notification,
                updateNotification: apiRoutes.identityUser.userprofile.updateNotification,
                changePassowrd: apiRoutes.identityUser.userprofile.changePassowrd,
                changeCompanyLogo: apiRoutes.identityUser.userprofile.changeCompanyLogo,
                changeProfile: apiRoutes.identityUser.userprofile.changeProfile,
                deleteDocument: apiRoutes.identityUser.userprofile.deleteDocument,
                fetchDocuments: apiRoutes.identityUser.userprofile.fetchDocuments,
                updateDocument: apiRoutes.identityUser.userprofile.updateDocument,
                uploadDocuments: apiRoutes.identityUser.userprofile.uploadDocuments
            }
        },
        userManagementEndpoints: {
            printerSettingsEndpoints: {
                updateprint: apiRoutes.user_management.printerSettings.updateprint,
                getsettings: apiRoutes.user_management.printerSettings.getsettings,
                uploadlogo: apiRoutes.user_management.printerSettings.uploadlogo
            },
            codeSettingsEndpoints: {
                getcodesettings: apiRoutes.user_management.settings.getcodesettings,
                updatecodesettings: apiRoutes.user_management.settings.updatecodesettings
            }
        }
    }
}