import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    customerEndpoints!: CustomerEndpoints;
    regionEndpoints!: RegionEndpoints;
}

export class CustomerEndpoints {
    create!: string;
    update!: string;
    getAllCustomer!: string;
    getCustomerById!: string;
    deleteCustomer!: string;
    changeStatus!: string;
    uploadImage!: string;
    uploadDocuments!: string;
    fetchDocuments!: string;
    updateDocument!: string;
    deleteDocument!: string;
    downloadCSV!: string;
    getterm!: string;
    createterm!: string;
}

export class RegionEndpoints {
    country!: string;
    state!: string;
    city!: string;
}

export const customerConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        customerEndpoints: {
            create: apiRoutes.user_management.customer.create,
            update: apiRoutes.user_management.customer.update,
            getAllCustomer: apiRoutes.user_management.customer.getAll,
            getCustomerById: apiRoutes.user_management.customer.getDetails,
            deleteCustomer: apiRoutes.user_management.customer.delete,
            changeStatus: apiRoutes.user_management.customer.changeStatus,
            uploadImage: apiRoutes.user_management.customer.uploadImage,
            uploadDocuments: apiRoutes.user_management.customer.uploadDocument,
            fetchDocuments: apiRoutes.user_management.customer.fetchDocument,
            updateDocument: apiRoutes.user_management.customer.updateDocument,
            deleteDocument: apiRoutes.user_management.customer.deleteDocument,
            downloadCSV: apiRoutes.user_management.customer.downloadcsv,
            getterm: apiRoutes.user_management.customer.getterm,
            createterm: apiRoutes.user_management.customer.createterm
        },
        regionEndpoints: {
            country: apiRoutes.user_management.region.country,
            state: apiRoutes.user_management.region.state,
            city: apiRoutes.user_management.region.city
        }
    }
}