<div class="box no-radius h-100 width-280 side-nav-close has-background-white" #sideNav>
  <div class="columns">
    <div class="column is-narrow">
      <img src="../../../../assets/img_app/image 2 (Traced).png" width="35px" height="35px">
    </div>
    <div class="column">
      <strong> Dispatch Management</strong>
    </div>
  </div>
  <mat-nav-list>
    <app-side-nav-menu-item router-link="dashboard" menu-icon="dashboard"
      menu-text="Dashboard"></app-side-nav-menu-item>
    <p class="menu-label">
      Configurations
    </p>
    <app-side-nav-menu-item router-link="customer" menu-icon="group" menu-text="Customers"></app-side-nav-menu-item>
    <app-side-nav-menu-item router-link="package/subscription" menu-icon="card_membership"
      menu-text="Subscriptions"></app-side-nav-menu-item>
    <app-side-nav-menu-item router-link="package/services" menu-icon="design_services"
      menu-text="Services"></app-side-nav-menu-item>

    <p class="menu-label">
      Reports
    </p>
    <app-side-nav-menu-item router-link="reports/orders" menu-icon="send_time_extension"
      menu-text="Orders"></app-side-nav-menu-item>
    <app-side-nav-menu-item router-link="reports/payments" menu-icon="credit_card"
      menu-text="Payments"></app-side-nav-menu-item>
    <app-side-nav-menu-item router-link="reports/failed-payment" menu-icon="sms_failed"
      menu-text="Failed Payments"></app-side-nav-menu-item>

    <p class="menu-label">
      Features
    </p>
    <app-side-nav-menu-item router-link="quote" menu-icon="request_quote" menu-text="Quote"></app-side-nav-menu-item>
  </mat-nav-list>

</div>