import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ToastService } from "@dmspro/notification";
import { AppFeaturesConstant, DecodeToken } from "@dmspro/shared-lib";

@Injectable({ providedIn: 'root' })
export class DispatchManagementGuard implements CanActivate {
    constructor(
        private router: Router,
        private toastService:ToastService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const jsonData= DecodeToken.getToken();
        if(jsonData){
            if(jsonData.features?.length>0){
                if(jsonData.features.findIndex(x=>x===AppFeaturesConstant.DISPATCH_MANAGEMENT)>-1){
                    return true;
                }
            }
        }

        this.toastService.notification.error('Unauthorized Access');
        this.router.navigate(['/account/dashboard']);
        return false;
    }
}