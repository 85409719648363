import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";


export class Configuration {
    baseUrl!: string;
    userEndpoints!: UserEndpointConfiguration;
}

export class UserEndpointConfiguration {
    customerEndpoints!: CustomerEndpoints;
    driverEndpoints!: DriverEndpoints;
    reportsEndpoints!: ReportsEndpoints;
}

export class CustomerEndpoints {
    getAllCustomer!: string;
}

export class DriverEndpoints {
    getAllDrivers!: string;
}

export class ReportsEndpoints {
    driverledger!: string;
    customerledger!: string;
    driversrevenue!: string;
    driversinspection!: string;
    driversreport!: string;
}

export const reportConfiguration: Configuration = {
    baseUrl: endpoints.user_management,
    userEndpoints: {
        customerEndpoints: {
            getAllCustomer: apiRoutes.user_management.customer.getAll,
        },
        driverEndpoints: {
            getAllDrivers: apiRoutes.user_management.driver.getAll,
        },
        reportsEndpoints: {
            customerledger: apiRoutes.user_management.reports.customerledger,
            driverledger: apiRoutes.user_management.reports.driverledger,
            driversinspection: apiRoutes.user_management.reports.driversinspection,
            driversrevenue: apiRoutes.user_management.reports.driversrevenue,
            driversreport: apiRoutes.user_management.reports.driversreport
        }
    }
}