import { apiRoutes } from "src/environments/env.routes";
import { endpoints } from "src/environments/environment";

export class Configuration {
    baseUrl!: string;
    requestDemoConfiguration!: RequestDemoConfiguration;
}

export class RequestDemoConfiguration {
    demoLink!: string;
}

export const requestDemoConfiguration: Configuration = {
    baseUrl: endpoints.identity,
    requestDemoConfiguration: {
        demoLink: apiRoutes.request_demo.demo
    }
}