import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ToastService } from "@dmspro/notification";
import { DecodeToken, LocalConstant } from "@dmspro/shared-lib";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/services/authentication.service";

@Injectable({ providedIn: 'root' })
export class SubscriptionGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastService: ToastService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authenticationService.currentUserValue?.id) {
            const userDetails = localStorage.getItem(LocalConstant.currentUser);
            const user = JSON.parse(userDetails ?? '{}');
            const tokenDetail = DecodeToken.getToken();
            if (tokenDetail) {
                if (tokenDetail?.subscription) {
                    if (tokenDetail.subscription >= new Date()) {
                        return true;
                    }
                }
            }
        }
        this.toastService.notification.warning('You are not authorized to access this page');
        this.router.navigate(['/account/dashboard']);
        return false;
    }
}