<div class="has-text-weight-bold is-size-4">
    SIGN UP
</div>
<div class="has-text-grey">
    Please sign-up to your account and start the adventure
</div>
<form [formGroup]="registerForm" class="mt-5" (submit)="onSubmit()">

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Your Name</mat-label>
        <input type="text" matInput formControlName="name" placeholder="Name">
        <mat-error>
            {{controlErrorMessage('name')}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="username" placeholder="Ex. pat@example.com">
        <mat-error>
            {{controlErrorMessage('username')}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password"
            placeholder="************">
        <mat-icon matSuffix class="pointer"
            (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
        <mat-error>
            {{controlErrorMessage('password')}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput [type]="showConfPassword ? 'text' : 'password'" formControlName="confirm"
            placeholder="************">
        <mat-icon matSuffix class="pointer"
            (click)="toggleConfPasswordVisibility()">{{showConfPassword?'visibility_off':'visibility'}}</mat-icon>
        <mat-error>
            {{controlErrorMessage('confirm')}}
        </mat-error>
        <mat-hint class="has-text-danger">
            {{passwordValidation()}}
        </mat-hint>
    </mat-form-field>

    <!-- <button type="submit" [ngClass]="isFormLoading?'is-loading':''" [disabled]="registerForm.invalid"
        class="button is-dark w-100 mt-4">Sign Up</button> -->

    <button mat-raised-button [disabled]="(registerForm.invalid || isFormLoading)" type="submit" color="primary"
        class="w-100">
        Register Account
        <mat-icon *ngIf="isFormLoading"><mat-spinner color="accent" diameter="18">
            </mat-spinner></mat-icon>
    </button>
</form>
<div class="has-text-centered has-text-black mt-4">
    Have an account?
    <a routerLink=".." style="display: inline-block;" class="ml-1">Sign-in</a>
</div>